/* eslint-disable */
import React, { useContext, useEffect } from "react";

import UserContext from "../UserContext";
import AccountSelectionScreen from "./AccountSelectionScreen";
import MobileNavBar from "../headers/MobileNavBar";
import ConsentFlowHeader from "../headers/ConsentFlowHeader";
import StatusBarCompletion from "../status/StatusBarCompletion";
import MobileNavControls from "../controls/MobileNavControls";

export default function WrappedAccountScreen(props) {
  const userContext = useContext(UserContext);

  const setSelectedAccounts = accountList => {
    props.onUpdateUser({
      ...userContext,
      selectedAccounts: accountList
    });
  };

  return (
    <div>
      <MobileNavBar url="chase.com" />
      <div className="preview-app-body row border-gray">
        <div className="container-fluid">
          <ConsentFlowHeader />
          <StatusBarCompletion numSteps={4} currentIndex={2} />
          <div className="row py-2">
            <div className="col-sm-10 offset-sm-1">
              <AccountSelectionScreen
                selectedAccounts={props.selectedAccounts}
                client={userContext.client}
                user={userContext.user}
                setSelectedAccounts={setSelectedAccounts}
              />
            </div>
          </div>
          <MobileNavControls
            scrollCentralPane={props.scrollCentralPane}
            section="confirm-accounts"
            goBack={props.goBack}
            goNext={props.goNext}
            dataPtName="acc_select"
            nextButtonDisabled={userContext.selectedAccounts.length === 0}
            nextButtonText="Next"
          />
        </div>
      </div>
    </div>
  );
}
