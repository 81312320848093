/* eslint-disable */
import React from 'react';
import TAG from '../tag';

import Lock from '../../images/lock.svg';

export default function LoginForm(props) {
  return (
    <div className="card">
      <div className="card-header">
        <span className="login-icon mr-3">
          <img width="24" height="24" src={Lock} alt={"Lock Graphic for Secure Data"}/>
        </span>
        <span className="font-small login-header-gray">
          Chase won't share your username and password with third parties.
        </span>
      </div>
      <div className="card-body">
        <form data-testid="login-form" onSubmit={props.onSubmit}>
          <div className="form-group">
            <label
              htmlFor="userInput"
              className="login-gray mb-1">
              Username
            </label>
            <input
              className="form-control"
              type="text"
              id="userInput"
              name="username"
              value="showcase_user"
              disabled />
          </div>
          <div className="form-group">
            <label
              htmlFor="passInput"
              className="login-gray mb-1">
              Password
            </label>
            <input
              className="form-control"
              type="password"
              id="passInput"
              name="password"
              value="********"
              disabled />
          </div>
          <div className="custom-control custom-checkbox text-right pb-2">
            <input
              className={`custom-control-input me-1 ${TAG.FORM}`}
              data-pt-name="prvw_login_check_token"
              type="checkbox"
              id="checkToken" />
            <label
              className="custom-control-label login-gray font-small"
              htmlFor="checkToken">
              Use Token
            </label>
          </div>
          <button
            className={`btn btn-primary btn-block ${TAG.DYNAMIC}`}
            data-pt-name="lnk_prvw_login_sign_in"
            type="submit"
            data-testid="sign-in"
          >
            Sign In
          </button>
          <div className="link-anchor pt-2">
            <a
              className={`link-anchor ${TAG.DYNAMIC}`}
              data-pt-name="lnk_prvw_login_forgot"
              href="#">
              Forgot Username/Password?
            </a>
          </div>
        </form>
      </div>
    </div>
  );
}
