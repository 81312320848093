import React, { useEffect, useState, useRef } from "react";
import { createRoot } from 'react-dom/client';

import "./styles/app.scss";
import ADSApp from "./accounts-and-data-sharing/App";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PWPManifest from "./data/manifests/pwp-manifest.json";
import Showcase from "./components/showcase";
import { fetchAllFeatures } from './api/showcase';

const allShowcases = [PWPManifest];

const renderShowcases = (showcases) => {
  return showcases.map((spec) => (
      <Route
        path={`/${spec.metadata.slug}`}
        key={spec.metadata.slug}
        element={<Showcase {...spec} />}
      />
  ));
};

const App = () => {
  const [showcases, setShowcases] = useState([]);
  const didMount = useRef(false)

  useEffect(async () => {
    const allFeatures = await fetchAllFeatures()
    if (allFeatures) {
      allFeatures.forEach((features) => {
        setShowcases(
          allShowcases.filter((spec) =>
            features
              .filter((f) => f.enabled)
              .map((f) => f.name)
              .includes(spec.metadata.featureName)
          )
        );
      });
    }

  }, []);

  useEffect(()=>{
    if (!didMount.current) {
      didMount.current = true
      return;
    }
    if (!document.querySelector("h1")) {
      window.location.replace("https://developer.chase.com/404")
    }
  }, [showcases])


  return (
    <Router>
      <Routes>
        <Route path="/" element={<ADSApp/>} />
        {/*<Route path="/pay-with-points/*" element={renderShowcases(allShowcases)} />*/}
        {renderShowcases(allShowcases)}
      </Routes>
    </Router>
  );
};

const container = document.getElementById('app');
const root = createRoot(container)

root.render(<App />);

export default App;
