import React from 'react';

export default function MobileNavBar(props) {
  return (
    <div className="preview-app-header row">
      <div className="col-3">
        <ul className="list-inline mb-0 nowrap">
          <li className="list-inline-item action-circle" />
          <li className="list-inline-item action-circle" />
          <li className="list-inline-item action-circle" />
        </ul>
      </div>
      <div className="col-7 px-0 my-1">
        <div className="url-bar text-center w-100">
          <span>{props.url}</span>
        </div>
      </div>
    </div>
  );
}
