import React, {
  useState,
} from "react";

interface useLoadImagesProps {
  images: { order: number; flow: number; path: string }[];
  slug: string;
}

const useLoadImages = ({ images, slug }: useLoadImagesProps) => {
  const [imageArr, setImgArr] = useState<string[]>([]);
  React.useEffect(() => {
    Promise.all(
      images.map((image) => import(`../../data/${slug}/images/${image.path}`))
    )
      .then((values) => setImgArr(values.map((val) => val.default)))
      .catch(function (err) {
        console.error(err.message);
      });
  }, [images, slug]);

  return imageArr;
};

export default useLoadImages;
