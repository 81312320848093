/* eslint-disable */
import React, {useEffect, useState} from 'react';

import ConsentFlowHeader from '../headers/ConsentFlowHeader';
import StatusBarCompletion from '../status/StatusBarCompletion';
import {
  AccountSelectionScreen,
  ClientInfoScreen,
  PermissionDisclosureScreen,
  ReviewFinalScreen
} from '../screens';
import { MockClient } from '../../data/MockClient';
import { MockAccountOwner } from '../../data/MockAccountOwner';

const screens = [ ClientInfoScreen, PermissionDisclosureScreen, AccountSelectionScreen, ReviewFinalScreen ];
const getScreen = (i, props) => {
  const Screen = screens[i];
  return <Screen {...props} />;
};

export default function ConsentClientInfoPage(props) {
  useEffect(() => {
    document.body.classList.remove('login-page');
  }, []);

  const [state, setState] = useState({
    step: 0,
    numSteps: 4,
    client: MockClient,
    user: MockAccountOwner,
    selectedAccounts: MockAccountOwner.accounts
  });

  const setSelectedAccounts = (accountList) => {
    setState({
      ...state,
      selectedAccounts: accountList
    });
  };

  const onClickBack = () => {
    const nextStep = state.step - 1;
    if (nextStep >= 0) {
      setState({
        ...state,
        selectedAccounts: state.user.accounts,
        step: nextStep
      });
    }
  };

  const onClickNext = () => {
    const nextStep = state.step + 1;
    if (nextStep < state.numSteps) {
      setState({
        ...state,
        step: nextStep
      });
    }
  };

  return (
    <div>
      <div className="container-fluid mb-allow-footer">
        <ConsentFlowHeader />
        <StatusBarCompletion
          numSteps={state.numSteps}
          currentIndex={state.step} />
        <div className="row pt-2">
          <div className="col-sm-6 offset-sm-3">
            {getScreen(state.step, {
              client: state.client,
              user: state.user,
              selectedAccounts: state.selectedAccounts,
              setSelectedAccounts: setSelectedAccounts,
              goBack: onClickBack
            })}
          </div>
        </div>
      </div>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row mx-2">
            <div className="col col-md-3 col-sm-6 offset-md-3 px-1">
              <button
                type="button"
                className="btn btn-cancel w-100">
                Cancel
              </button>
            </div>
            <div className="col col-md-3 col-sm-6 px-1">
              <button
                type="button"
                className="btn btn-next w-100"
                onClick={onClickNext}>
                {state.step + 1 === state.numSteps ? 'Connect' : 'Next'}
              </button>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
