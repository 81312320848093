import React, { useContext, useRef, useEffect, useState } from "react";
import _, { clamp } from "lodash";
import ReactMarkdown from "react-markdown";
import { StoreContext, useWindowSize, useLoadImages } from "../../hooks";
export interface ContentPaneProps {
  documentation: { flow: number; title: string; content: string }[];
  images: { order: number; flow: number; path: string }[];
  slug: string;
}
export const scrollToSection = (i: number, isMobile: boolean) => {
  /* callback function added to click event on same-page links */
  const nextGrouping = document.querySelector(`#screen-point-${i}`);
  const navbarHeight = document.querySelector(".sc-navbar")?.clientHeight || 64;
  const textSectionForNextGrouping =
    nextGrouping?.querySelector(".text-grouping"); // scrolls to text section
  isMobile
    ? ((document.querySelector("html") as HTMLElement).scrollTop = (
        textSectionForNextGrouping!.parentNode as HTMLElement
      ).offsetTop)
    : ((
        document.querySelector("#doc-and-preview-panes") as HTMLElement
      ).scrollTop =
        (textSectionForNextGrouping!.parentNode as HTMLElement).offsetTop -
        navbarHeight);
};

const DocumentationPane: React.FC<ContentPaneProps> = (props) => {
  const { documentation, images, slug } = props;
  const docsByFlow = _.groupBy(documentation, "flow");
  const documentationPaneRef = useRef<HTMLDivElement>(null);
  const imagesRefArr = useLoadImages({ images, slug });
  const windowSize = useWindowSize();

  const [flowBoundaries, setFlowBoundaries] = useState<
    { flow: number; position: number }[]
  >([]);
  const [documentationBoundaries, setDocumentationBoundaries] = useState<
    { docIdx: number; position: number; element: Element }[]
  >([]);

  const {
    currentFlow,
    setCurrentFlow,
    setEndpointSnippet,
    currentDocNumber,
    setCurrentDocNumber,
    setImageIdx,
    isMobile,
  } = useContext(StoreContext);

  useEffect(() => {
    /*
    When images are first loaded or window is resized,
    recapture boundaries for documentation and flow sections
    */
    if (documentationPaneRef.current) {
      const flowSections = document.getElementsByClassName("flow-section");
      const docAndPreviewPane = documentationPaneRef.current;
      const flowBoundaries = Array.from(flowSections).map((s, i) => ({
        flow: i,
        position: s.getBoundingClientRect().bottom,
      }));
      setFlowBoundaries(flowBoundaries);

      const documentationSections =
        document.getElementsByClassName("image-grouping");
      const documentationBoundaries = Array.from(documentationSections).map(
        (s, i) => ({
          docIdx: i,
          position:
            s.getBoundingClientRect().bottom + docAndPreviewPane.scrollTop,
          element: s,
        })
      );
      setDocumentationBoundaries(documentationBoundaries);
    }
  }, [imagesRefArr, windowSize]);

  useEffect(() => {
    /* When content first renders, links are tagged and given click events.*/
    addAATaggingToLinks();
  }, []);

  useEffect(() => {
    /*
    useEffect that updates endpointSnippet context for use in <CodePane/>

    */
    if (documentationBoundaries.length) {
      const currentImageSection =
        documentationBoundaries[currentDocNumber].element;
      const currCodeSnippet = Array.from(
        currentImageSection.getElementsByTagName("code")
      );
      if (currCodeSnippet.length) {
        const codeSnippetText = currCodeSnippet[0].innerHTML;
        setEndpointSnippet(codeSnippetText);
      }
    }
  }, [currentFlow, currentDocNumber]);

  useEffect(() => {
    const nextImgIndex = clamp(currentDocNumber, 1, images.length) - 1;
    setImageIdx(nextImgIndex);
  }, [currentDocNumber]);

  const checkScrollPos = () => {
    /* onScroll event that checks the scroll bar position, compares it against objects, and sets state accordingly*/
    const currentScrollPosition =
      document.querySelector("#doc-and-preview-panes")?.scrollTop || 0;
    const navbarHeight =
      document.querySelector(".sc-navbar")?.clientHeight || 64;
    const scrollCushion = 5;
    const flowInView = flowBoundaries.find(
      (boundary) =>
        boundary.position - navbarHeight - scrollCushion >=
        currentScrollPosition
    );
    const docInView = documentationBoundaries.find(
      (boundary) =>
        boundary.position - navbarHeight - scrollCushion >=
        currentScrollPosition
    );
    if (docInView) {
      const newDocIdx = docInView?.docIdx;
      setCurrentDocNumber(newDocIdx);
    }
    if (flowInView) {
      setCurrentFlow(flowInView.flow);
    }
  };

  const addAATaggingToLinks = () => {
    /* adds tagging and click events to links where href attribute has value '#' */
    if (documentationPaneRef.current) {
      const linkTags = documentationPaneRef.current.getElementsByTagName("a");
      const handleEnterClick = (
        event: KeyboardEvent,
        aTag: HTMLAnchorElement,
        i: number
      ) => {
        if (event.key === "Enter") {
          event.preventDefault();
          scrollToSection(i + 1, isMobile);
        }
      };
      Array.from(linkTags).forEach((aTag: HTMLAnchorElement, i) => {
        if (aTag.parentElement) {
          if (
            aTag.hasAttribute("href") &&
            aTag.getAttribute("href") === "https://developer.chase.com"
          ) {
            aTag.classList.add("chaseanalytics-track-link");
            aTag.setAttribute("data-pt-name", `nav_central_to_homepage`);
            const LOCAL = "localhost";
            const DEV = "showcase.eks.apix.dev.aws.jpmchase.net";
            const UAT =
              "apidemo-uat.chase.com" ||
              "showcase.eks.apix.uat.aws.jpmchase.net";
            const PROD =
              "apidemo.chase.com" || "showcase.eks.apix.prod.aws.jpmchase.net";
            let devChaseLink = "";

            const updateHomepageLink = (link: string) => {
              aTag.innerText = link;
              aTag.setAttribute("href", link);
            };

            switch (window.location.hostname) {
              case DEV:
                devChaseLink = "https://portal.eks.apix.dev.aws.jpmchase.net/";
                break;
              case PROD:
                devChaseLink = "https://developer.chase.com";
                break;
              default:
                devChaseLink = "https://developer-uat.chase.com";
            }

            updateHomepageLink(devChaseLink);
          }
        }
      });
    }
  };

  const renderScreenGrouping = (
    flowKey: number,
    i: number,
    currGroupingNumber: number,
    doc: any
  ) => {
    const cgnIsNotFirstOrLastTwo = !(
      !currGroupingNumber || currGroupingNumber > documentation.length - 3
    );

    const getTitleName = (nextSectionIndex: number) => {
      const titleName = documentation[nextSectionIndex].title.replaceAll(
        "-",
        " "
      );
      return titleName.charAt(0).toUpperCase() + titleName.slice(1);
    };

    return (
      <div
        id={`screen-point-${currGroupingNumber}`}
        className={`image-grouping`}
        key={`${flowKey}-${i}`}
      >
        {isMobile ? (
          <div className="preview-pane-container">
            {images[currGroupingNumber] && currGroupingNumber !== 0 ? (
              <img
                className={`preview-pane-image ${
                  documentation[currGroupingNumber - 1].title
                }-image`}
                alt={`${images[currGroupingNumber - 1].path.split(".png")[0]}-${
                  documentation[currGroupingNumber - 1].title
                }-image`}
                src={imagesRefArr[currGroupingNumber - 1]} // - 1 to offset empty space for first image
              />
            ) : null}
          </div>
        ) : null}
        <div className="text-grouping">
          <ReactMarkdown>{doc.content}</ReactMarkdown>
          {cgnIsNotFirstOrLastTwo ? (
            <p className="p-with-link">
              <button
                onClick={() =>
                  scrollToSection(currGroupingNumber + 1, isMobile)
                }
                className="chaseanalytics-track-link"
                data-pt-name={`nav_central_to_prvw_${currGroupingNumber}`}
              >
                {getTitleName(currGroupingNumber + 1)}
              </button>
            </p>
          ) : null}
        </div>
        <hr />
      </div>
    );
  };

  let currGroupingNumber: number = 0;

  return (
    <>
      <div
        onScroll={() => checkScrollPos()}
        ref={documentationPaneRef}
        id="doc-and-preview-panes"
        data-testid="doc-and-preview-panes"
        tabIndex={0}
      >
        {_.values(docsByFlow).map((docs, flowKey) => (
          <div key={flowKey} className={`api-flow-${flowKey} flow-section`}>
            {docs.map((doc, i) => {
              const screenGrouping = renderScreenGrouping(
                flowKey,
                i,
                currGroupingNumber,
                doc
              );
              currGroupingNumber++;
              return screenGrouping;
            })}
          </div>
        ))}
      </div>
    </>
  );
};

export default DocumentationPane;
