import React, { useEffect, useState } from "react";
import { CodePane, DocumentationPane, ImagePane } from "./panes";
import { NavBar } from "./presentational";
import { Helmet } from "react-helmet";
import { useWindowSize, StoreContext } from "../hooks";
interface ShowcaseProps {
  metadata: {
    title: string;
    slug: string;
    featureName: string;
    metaDescription: string;
  };
  images: { order: number; flow: number; path: string }[];
  documentation: { flow: number; title: string; content: string }[];
  snippets: {
    flow: number;
    apiName: string;
    language: string;
    content: string;
  }[];
}

const Showcase: React.FC<ShowcaseProps> = (props) => {
  const { images, snippets, documentation, metadata } = props;
  const { title, metaDescription, slug } = metadata;
  const [currentFlow, setCurrentFlow] = useState(0);
  const [endpointSnippet, setEndpointSnippet] = useState("");
  const [currentDocNumber, setCurrentDocNumber] = useState(0);
  const [imageIdx, setImageIdx] = useState(0);
  const windowWidth = useWindowSize().width;
  const [isMobile, setIsMobile] = useState(windowWidth < 769);

  useEffect(() => {
    // reevaluates isMobile status based on changes in windowWidth
    setIsMobile(windowWidth < 769);
  }, [windowWidth]);

  return (
    <>
      <Helmet>
        <title>{`${title} Demo | Chase Developer`}</title>
        <meta name="description" content={`${metaDescription}`} />
      </Helmet>
      <StoreContext.Provider
        value={{
          currentFlow,
          setCurrentFlow,
          endpointSnippet,
          setEndpointSnippet,
          currentDocNumber,
          setCurrentDocNumber,
          imageIdx,
          setImageIdx,
          isMobile,
          setIsMobile,
        }}
      >
        <main className="main-class">
          <NavBar />
          <div className="pane-container">
            {!isMobile ? <ImagePane images={images} slug={slug} /> : null}
            <DocumentationPane
              images={images}
              documentation={documentation}
              slug={slug}
            />
            <CodePane snippets={snippets} />
          </div>
        </main>
      </StoreContext.Provider>
    </>
  );
};

export default Showcase;
