/* eslint-disable */
import React from "react";
import WordmarkWhite from "../../images/wordmark-white.png";

export default function ConsentFlowHeader(props) {
  return (
    <header className="consent-header row">
      <div className="col consent-logo-wrapper text-center">
        <img className="consent-logo" src={WordmarkWhite} alt="Chase Logo" />
      </div>
    </header>
  );
}
