import React, { useEffect } from "react";

const ScrollableScreen = (props) => {
  const { contentSection, children } = props;

  useEffect(() => {
    const centralPaneElement = document.querySelector(`#${contentSection}`);
    centralPaneElement.scrollIntoView({ behavior: "smooth" });
  }, [contentSection]);

  return <>{children}</>;
};

export default ScrollableScreen;
