/* eslint-disable */
import React, { useEffect, useState } from "react";

import DeveloperHeader from "../headers/DeveloperHeader";
import { CentralPane, CodePane, PreviewPane } from "../developer-panes";
import UserContext from "../UserContext";
import Flows from "../../data/Flows";
import { MockClient } from "../../data/MockClient";
import TAG from "../tag";
import { fetchAllAPIIds, fetchDataSet } from "../../api/tryme";
import { Link } from "react-router-dom";

const DeveloperFooter = () => {
  return (
    <footer className="developer-footer">
      <div className="developer-footer-content clearfix mx-3 my-3">
        <div className="float-start">
          <span className="align-middle">
            <a
              className={`text-decoration-none link-dark ${TAG.EXTERNAL}`}
              data-pt-name="ft_privacy_policy"
              href="https://www.chase.com/digital/resources/privacy-security/privacy/online-privacy-policy"
            >
              Online Privacy Policy
            </a>{" "}
            |
            <a
              className={`text-decoration-none link-dark ${TAG.EXTERNAL}`}
              data-pt-name="ft_terms_of_use"
              href="https://developer.chase.com/terms"
            >
              {" "}
              Terms of Use
            </a>
          </span>
        </div>
        <div className="float-end">
          <span className="align-middle">&copy; {new Date().getFullYear()} JPMorgan Chase & Co. All rights reserved.</span>
        </div>
      </div>
    </footer>
  );
};

export default function AccountsAndDataSharingShowcase(props: any) {
  const { title, documentation } = props;

  const [state, setState] = useState({
    client: MockClient,
    user: { accounts: [] },
    selectedAccounts: [],
    isLoggedIn: window.location.pathname === "/logged-in"
  });

  const [flow, setFlow] = useState(Flows.GET_CONSENT);

  const updateFlow = (newFlow: number) => {
    setFlow(currentFlow => {
      return newFlow;
    });
  };

  const isLoggedIn = window.location.pathname === "/logged-in";
  return (
    <>
      <UserContext.Provider value={state}>
      <link rel="stylesheet"
      href="~bootstrap/scss/bootstrap"/>
        <DeveloperHeader />
        <main className="my-5">
          <div className="container-fluid mt-neg">
            <div className="row">
              <CentralPane
                updateFlow={updateFlow}
                isLoggedIn={isLoggedIn}
                flow={flow}
                documentation={documentation}
              />
              <PreviewPane
                onUpdateUser={setState}
                flow={flow}
                title={title}
                selectedAccounts={state.selectedAccounts}
              />
              <CodePane
                flow={flow}
                updateFlow={updateFlow}
                isLoggedIn={isLoggedIn}
              />
            </div>
          </div>
        </main>
      </UserContext.Provider>
      <DeveloperFooter />
    </>
  );
}
