import {  createContext } from "react";

export interface StoreInterface {
  currentFlow: number;
  setCurrentFlow: (newFlow: number) => void;
  endpointSnippet: string;
  setEndpointSnippet: (newSnippet: string) => void;
  currentDocNumber: number;
  setCurrentDocNumber: (newDocNumber: number) => void;
  imageIdx: number;
  setImageIdx: (newImageIdx: number) => void;
  isMobile: boolean;
  setIsMobile: (isMobile: boolean) => void;
}

export const StoreInitialContext: StoreInterface = {
  currentFlow: 0,
  setCurrentFlow: () => {},
  endpointSnippet: '',
  setEndpointSnippet: () => {},
  currentDocNumber: 0,
  setCurrentDocNumber: () => {},
  imageIdx: 0,
  setImageIdx: () => {},
  isMobile: false,
  setIsMobile: () => {},
};

export const StoreContext = createContext<StoreInterface>(StoreInitialContext);
