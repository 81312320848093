import {useTheme} from './ThemeContext';
import {DARK_THEME, LIGHT_THEME} from './Theme';

export default function ThemeToggler() {
  const {toggleTheme, isLightTheme, isDarkTheme} = useTheme();

  return (
    <div className="theme-switcher nav-item d-inline-flex align-items-center">
      <div className="custom-control custom-switch">
        <input
          id="toggler"
          className="custom-control-input"
          type="checkbox"
          onChange={toggleTheme}
          checked={isDarkTheme}
        />
        <label className="custom-control-label" htmlFor="toggler">
          <span className="sr-only">Toggle theme</span>
        </label>
        {isDarkTheme && <span className={DARK_THEME}>Dark</span>}
        {isLightTheme && <span className={LIGHT_THEME}>Light</span>}
      </div>
    </div>
  );
}
