import React from 'react';

export default function ConnectedAccountList(props) {
  return (
    <ul className="list-group border-none">
      {props.accounts && props.accounts.map((account, index) => (
        <li
          key={index}
          className="list-group-item d-flex justify-content-between font-small fw-600 border-none fs-80-pct py-1">
          {account.name}
          <span className="align-self-end">
            {account.balance.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}
          </span>
        </li>
      ))}
    </ul>
  );
}
