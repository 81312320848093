import React, { useState } from "react";

const CopyButton: React.FC<{ codeSnippetText: string }> = ({
  codeSnippetText,
}) => {
  const COPY_TO_CLIPBOARD = "Copy to clipboard";
  const COPIED = "Copied";
  const [copyToolTip, setCopyToolTip] = useState(COPY_TO_CLIPBOARD);

  const handleCopyCode = () => {
    window.navigator.clipboard.writeText(codeSnippetText || "");
    setCopyToolTip(COPIED);
    setTimeout(() => {
      setCopyToolTip(COPY_TO_CLIPBOARD);
    }, 2000);
  };

  return (
    <div id="copy-button-container">
      <button
        data-testid="copy-to-clipboard"
        className={`btn btn-copy me-2 copy-button`}
        data-pt-name={`lnk_code_copy`}
        aria-label="Copy to clipboard"
        onClick={handleCopyCode}
      />
      <span
        className="copy-tooltip-text btn btn-secondary me-2"
        data-toggle="tooltip"
        data-placement="top"
      >
        <small data-testid="copy-tooltip-text" id="copy-tooltip-text">
          {copyToolTip}
        </small>
      </span>
    </div>
  );
};

export default CopyButton;
