/* eslint-disable */
import React from 'react';
import DeveloperCard from '../cards/DeveloperCard';

export default function LoggedInContent(props) {
  return (
    <div>
      <h2 className="py-1 developer-heading">
        Share Chase account data with other apps
      </h2>
      <p>In this interactive demo, we’ll step you the through process a customer goes through when they link their Chase
        accounts to a third-party app. Each step outlines both the customer interactions as well as the corresponding
        implementation details, including code samples.</p>
      <p>This demo is divided into three parts:</p>
      <DeveloperCard>
        <p>
          <strong className="fw-bold">1. Get User Consent</strong>
          &nbsp;- Bacon <a href="#">Aggregator-oauth</a> ground round fatback bresaola landjaeger shankle tenderloin
          burgdoggen shoulder jerky t-bone pork belly pork loin cupim kevin flank.
        </p>
        <p>
          <strong className="fw-bold">2. Link Chase accounts</strong>
          &nbsp;- Calling the <a href="#">User Consent API</a> allows developers to obtain a Chase customer/user's
          consent for an aggregator to access data on the apps's behalf.
        </p>
        <p>
          <strong className="fw-bold">3. Share Data</strong>
          &nbsp;- Accessing account details through <a href="#">Data sharing FDX API</a> allows developers to display
          balances, transactions and other supported information in the app.
        </p>
      </DeveloperCard>
      <div className="br-developer my-5" />
      <h3 className="mb-5 pt-1 developer-subheading">
        Get user consent
      </h3>
      <p>A Chase user starts within a third-party app to set up payments with Sunshine Wallet. Before the user can link
        their Chase deposit accounts, they must be logged in to Sunshine Wallet. (Login experience not shown) The OAuth
        token used to share user data that is created using the OAuth consent flow must be associated with the logged in
        user.</p>
      <p>An application must be preregistered with Chase using the Account Aggregation Application Onboarding API, which
        also shares its logo, name, and data categories that required to support its use cases. The user must consent to
        the data categories provided.</p>
      <p>The user chooses to connect with Chase to share their accounts and is directed to a screen instructing them
        what information Chase collects and how we use it.</p>
      <p className="fw-bold">Giving permission</p>
      <p>Start by giving Sunshine Wallet permission to move your money in and out of your Chase accounts. We’ll direct
        you to Chase's secure login site, where you can log in and connect your accounts. Then we'll return you here and
        allow you to use the move money experience.</p>
      <div className="br-developer my-5" />
      <h3 className="mb-5 pt-1 developer-subheading">
        Provide Consent (provide-consent)
      </h3>
      <p>When a user consents to connect their Chase bank accounts to an application, the application presents what data
        is collected and how it is used.</p>
      <p>Selecting the Continue button directs the user to the Chase website or mobile application. The application must
        launch the OAuth page within a secure native browser. Do not embed the page within an application container or
        iframe. The user logs in and gives permission to the Sunshine wallet application to access their data.</p>
    </div>
  );
}
