/* eslint-disable */
export default [
  {
    name: "Get Consent",
    code: [
      {
        name: "Javascript",
        language: "javascript",
        code: `const url= 'https://${window.location.host}/mock/aggregator-oauth/v1/authorize?response_type=code&client_id=SUNSHINE_WALLET&redirect_uri=showcaseApp/payment-settlement/eligible-accounts&state=apigeeapp&scope=aggregator';
/*ES5*/
var xhttp = new XMLHttpRequest();
xhttp.onreadystatechange = function() {
  if (xhttp.readyState === 4) {
    console.log(this.status);
    console.log(this.response);
  }
};
xhttp.open('GET', url, true);
xhttp.setRequestHeader('Content-Type', 'application/json');
xhttp.setRequestHeader('playground-id-token', '{copied-playground-token-id}');
xhttp.send();

/*ES6*/
fetch(url, {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    'playground-id-token': '{copied-playground-token-id}'
  }
}).then(response => {
    console.log(response.status);
    return response.json();
  })
  .then(data => {
    console.log(data);
  })
  .catch(error => console.log(error));`,
      },
      {
        name: "YAML",
        language: "yaml",
        code: `authorization:
  type: string
  description: OAuth bearer token will be sent in this header as per the OAuth2 spec. This header will be used by the API gateway. The API does not need to do anything with this header
  name: authorization
  in: header
  required: true
authorization2:
  name: authorization2
  type: string
  description: Additional authorization on top of standard OAuth token in the Authorization Header. The use of this header is different for 3-legged and 2-legged use cases
  required: true
  in: header
playground-id-token:
  name: playground-id-token
  type: string
  description: Token identifier for playground environment
  required: true
  in: header
trace-id:
  name: trace-id
  type: string`,
      },
    ],
  },
  {
    name: "Selecting Accounts",
    code: [
      {
        name: "Javascript",
        language: "javascript",
        code: `const url= 'https://${window.location.host}/mock/jpmc/servicing/inquiry-maintenance/fdx/v4/accounts';
/*ES5*/
var xhttp = new XMLHttpRequest();
xhttp.onreadystatechange = function() {
  if (xhttp.readyState === 4) {
    console.log(this.status);
    console.log(this.response);
  }
};
xhttp.open('GET', url, true);
xhttp.setRequestHeader('Content-Type', 'application/json');
xhttp.setRequestHeader('authorization', 'sitcillumsintmagnaproident');
xhttp.setRequestHeader('playground-id-token', '{copied-playground-token-id}');
xhttp.send();

/*ES6*/
fetch(url, {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    'authorization': 'sitcillumsintmagnaproident',
    'playground-id-token': '{copied-playground-token-id}'
  }
}).then(response => {
    console.log(response.status);
    return response.json();
  })
  .then(data => {
    console.log(data)
  })
  .catch(error => console.log(error));`,
      },
      {
        name: "YAML",
        language: "yaml",
        code: `  /accounts:
    get:
      operationId: searchForAccounts
      tags:
        - Account Information
      summary: Search for account
      description: >-
        Retrieve account information and a list of account transactions. You can
        specify one or more accounts, and limit the list of transactions by date
        and time. You can also specify the total number of transactions to
        return, and whether you want to the return list to start at the first
        available transaction record, or at a record further into the available
        data.
      parameters:
        - in: header
          name: 'playground-id-token'
          schema:
            type: 'string'
          description: 'Token identifier for playground environment'
        - $ref: '#/components/parameters/authorizationParam'
          examples:
            Example1LightweightPayload:
              value: abcd12345
        - $ref: '#/components/parameters/interactionIdParam'
          examples:
            Example1LightweightPayload:
              value: 123456
        - $ref: '#/components/parameters/resultTypeParam'
          examples:
            Example1LightweightPayload:
              value: lightweight
      responses:
        200:
          description: An array of accounts
          content:
            application/json:
              schema:
                $ref: '#/components/schemas/Accounts'`,
      },
    ],
  },
  {
    name: "Verify Payment Networks",
    code: [
      {
        name: "Javascript",
        language: "javascript",
        code: `const url= 'https://${window.location.host}/mock/jpmc/servicing/inquiry-maintenance/fdx/v4/accounts/{accountId}/payment-networks';
/*ES5*/
var xhttp = new XMLHttpRequest();
xhttp.onreadystatechange = function() {
  if (xhttp.readyState === 4) {
    console.log(this.status);
    console.log(this.response);
  }
};
xhttp.open('GET', url, true);
xhttp.setRequestHeader('Content-Type', 'application/json');
xhttp.setRequestHeader('authorization', 'commododolore');
xhttp.setRequestHeader('playground-id-token', '{copied-playground-token-id}');
xhttp.send();

/*ES6*/
fetch(url, {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    'authorization': 'commododolore',
    'playground-id-token': '{copied-playground-token-id}'
  }
}).then(response => {
    console.log(response.status);
    return response.json();
  })
  .then(data => {
    console.log(data)}
  })
  .catch(error => console.log(error));`,
      },
      {
        name: "YAML",
        language: "yaml",
        code: `/accounts/{accountId}/payment-networks:
  get:
    operationId: getAccountPaymentNetworks
    tags:
      - Money Movement
    description: Get payment networks supported by the account
    summary: Get payment networks supported by the account. Chase supports ACH payment network only.
    parameters:
      - in: header
        name: 'playground-id-token'
        schema:
          type: 'string'
        description: 'Token identifier for playground environment'
      - $ref: '#/components/parameters/authorizationParam'
      - $ref: '#/components/parameters/interactionIdParam'
      - $ref: '#/components/parameters/accountIdParam'
    responses:
      200:
        description: Information required to execute a payment transaction against this account
        content:
          application/json:
            schema:
              $ref: '#/components/schemas/AccountPaymentNetworkList'
            examples:
              Tokenized Account Number:
                value:
                  {
                  "paymentNetworks": [
                    {
                      "bankId": "121000358",
                      "identifier": "987654321",
                      "identifierType": "TOKENIZED_ACCOUNT_NUMBER",
                      "type": "US_ACH",
                      "transferIn": true,
                      "transferOut": true
                    }]
                  }

      400:
        $ref: '#/components/responses/BadRequest'
      401:
        $ref: '#/components/responses/Unauthorized'
      404:
        $ref: '#/components/responses/NotFound'
      500:
        $ref: '#/components/responses/InternalServerError'`,
      },
    ],
  },
  {
    name: "Setup Payments",
    code: [
      {
        name: "Javascript",
        language: "javascript",
        code: `const url= 'https://${window.location.host}/mock/jpmc/servicing/inquiry-maintenance/fdx/v4/accounts/{accountId}/transactions';
/*ES5*/
var xhttp = new XMLHttpRequest();
xhttp.onreadystatechange = function() {
  if (xhttp.readyState === 4) {
    console.log(this.status);
    console.log(this.response);
  }
};
xhttp.open('GET', url, true);
xhttp.setRequestHeader('Content-Type', 'application/json');
xhttp.setRequestHeader('authorization', 'eteiusmodconsecteturamet');
xhttp.setRequestHeader('playground-id-token', '{copied-playground-token-id}');
xhttp.send();

/*ES6*/
fetch(url, {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    'authorization': 'eteiusmodconsecteturamet',
    'playground-id-token': '{copied-playground-token-id}'
  }
}).then(response => {
    console.log(response.status);
    return response.json();
  })
  .then(data => {
    console.log(data);
  }
  .catch(error => console.log(error));`,
      },
      {
        name: "YAML",
        language: "yaml",
        code: `/accounts/{accountId}/transactions:
  get:
    operationId: searchForAccountTransactions
    tags:
      - Account Transactions
    description: >-
      Retrieves account transactions <br> <br>Notes <br>1. Endpoint returns
      all transactions for the dates specified,regardless of the times
      specified. <br>2. If startTime and endTime parameters are equal (in this
      case, the range requested is 0), then one day of transactions is
      returned. <br>3. If the end date is the current date, only transactions
      until the current time are returned. The same query, executed later in
      the day, may return additional transactions.
      Transactions are pagination supported.You can set the Limit field in the request to specify the number of transactions returned..
      The client should look for 'links.next.href' to determine if additional API calls are required
      to retrieve the data requested.The client should continue to make API calls until the key is no longer returned in
      the response.
    summary: Search for account transactions
    parameters:
      - in: header
        name: 'playground-id-token'
        schema:
          type: 'string'
        description: 'Token identifier for playground environment'
      - $ref: '#/components/parameters/authorizationParam'
        examples:
          Example1DepositTransactionsPayload:
            value: abcd12345
      - $ref: '#/components/parameters/interactionIdParam'
        examples:
          Example1DepositTransactionsPayload:
            value: 123456
      - $ref: '#/components/parameters/accountIdParam'
        examples:
          Example1DepositTransactionsPayload:
            value: 4749855
      - $ref: '#/components/parameters/startTimeParam'
        examples:
          Example1DepositTransactionsPayload:
            value: 2019-02-26T00:00:00.000Z
      - $ref: '#/components/parameters/endTimeParam'
        examples:
          Example1DepositTransactionsPayload:
            value: 2019-07-31T00:00:00.000Z
      - $ref: '#/components/parameters/offsetParam'
        examples:
          Example1LocTransactionsPayload:
            value: "encoded-offset"
      - $ref: '#/components/parameters/limitParam'
        examples:
          Example1LocTransactionsPayload:
            value: 10
    responses:
      200:
        description: >-
          An array of transactions, which can be either DepositTransaction, LocTransaction (line of credit), InvestmentTransaction or LoanTransaction
          Transactions can be paginated. Use the links field to
          determine if you need additional API calls to retrieve the data you want.
        content:
          application/json:
            schema:
              $ref: '#/components/schemas/Transactions'`,
      },
    ],
  },
];
