import React from 'react';
import CollapsableList from '../lists/CollapsableList';

export default function PermissionDisclosureScreen(props) {
  const client = props.client;
  return (
    <div className="mx-3">
      <h4 className="h6">What the application wants to know</h4>
      <CollapsableList
        items={client.permissions}
        initialIndex={0} />
    </div>
  );
}
