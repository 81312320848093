import React from 'react';
import TAG from "../tag"

export default function MobileNavControls(props) {
  let btnBaseClass = 'btn';
  if (props.isPartnerSite) {
    btnBaseClass += ' btn-partner';
  }

  return (
    <div className="row mx-2 pb-2">
      <div className="col-sm-6 px-1">
        <button
          type="button"
          className={`${btnBaseClass} btn-cancel w-100 ${TAG.DYNAMIC}`}
          data-pt-name={`lnk_prvw_${props.dataPtName}_bck`}
          onClick={props.goBack}>
          Cancel
        </button>
      </div>
      <div className="col-sm-6 px-1">
        <button
          type="button"
          disabled={props.nextButtonDisabled}
          aria-disabled={!props.nextButtonDisabled}
          className={`${btnBaseClass} btn-next w-100 ${TAG.DYNAMIC}`}
          data-pt-name={`lnk_prvw_${props.dataPtName}_nxt`}
          onClick={props.goNext}>
          {props.nextButtonText}
        </button>
      </div>
    </div>
  );
}
