/* eslint-disable */
import React from "react";

import { ThemeProvider } from "@moneta/moneta-web-react";

import { AccountsAndDataSharingShowcase } from "./pages";
import ADSHead from "./head-extras/ADSHead";

function ADSApp(props) {
  return (
    <>
      <ADSHead />
      <ThemeProvider>
        <AccountsAndDataSharingShowcase />
      </ThemeProvider>
    </>
  );
}

export default ADSApp;
