import React, { useContext, useRef } from "react";
import { NextButton } from "../presentational";
import { StoreContext, useLoadImages, useWindowSize } from "../../hooks";
import { scrollToSection } from "./DocumentationPane";

interface ImageProps {
  images: { order: number; flow: number; path: string }[];
  slug: string;
}

const ImagePane: React.FC<ImageProps> = ({ images, slug }) => {
  const imagesRefArr = useLoadImages({ images, slug });
  const { imageIdx, setImageIdx, isMobile } = useContext(StoreContext);
  const imagePaneRef = useRef<HTMLDivElement | null>(null);
  const windowWidth = useWindowSize().width;

  const handleClick = (nextImageIdx: number) => {
    /* handles component local changes of imageIdx */
    setImageIdx(nextImageIdx);
    nextImageIdx !== 0
      ? scrollToSection(nextImageIdx + 1, isMobile)
      : scrollToSection(0, isMobile);
  };

  return (
    <div className="image-pane" data-testid="image-pane" ref={imagePaneRef}>
      {imageIdx !== 0 ? (
        <button
          className="image-pane-btn back-btn chaseanalytics-track-link"
          onClick={() => handleClick(imageIdx - 1)}
          aria-label="Navigate to previous image"
          data-pt-name={`lnk_imgpane_prev`}
        >
          <NextButton />
        </button>
      ) : null}
      <div className="image-pane-center">
        {imagesRefArr.length ? (
          <img
            className={`preview-pane-image`}
            alt={`${images[imageIdx].path.split(".png")[0]}`}
            src={imagesRefArr[imageIdx]}
          />
        ) : null}
        <div className="dots">
          {imagesRefArr.map((imgRef, dotIdx) => {
            const isSelected = imageIdx === dotIdx ? "selected" : "";
            return (
              <button
                key={imgRef}
                aria-label={`Navigate to image ${dotIdx}`}
                data-pt-name={`lnk_prvw_dotindex${dotIdx}`}
                className={`image-idx-dot ${isSelected} chaseanalytics-track-link`}
                onClick={() => handleClick(dotIdx)}
              ></button>
            );
          })}
        </div>
      </div>

      {imageIdx !== imagesRefArr.length - 1 ? (
        <button
          className="image-pane-btn next-btn chaseanalytics-track-link"
          onClick={() => handleClick(imageIdx + 1)}
          aria-label="Navigate to next image"
          data-pt-name={`lnk_imgpane_next`}
        >
          <NextButton />
        </button>
      ) : null}
    </div>
  );
};

export default ImagePane;
