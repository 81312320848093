import { useEffect, useState, useRef } from "react";

const useEffectSkipFirstRender: typeof useEffect = (cb, dep) => {
  const initializeRef = useRef<boolean>(false);

  useEffect((...args) => {
    if (initializeRef.current) {
      cb(...args);
    } else {
      initializeRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dep);
};

export default useEffectSkipFirstRender;
