
const MockClient = {
  name: 'Sunshine Wallet',
  permissions: [{
    name: 'Contact information',
    description: 'This includes personally-identifiable info, including the owner names, primary address, email address, and phone number for each account, as well as the contact info from your profile.'
  }, {
    name: 'Account names, types and other details',
    description: 'This includes details such as the nicknames and partial account numbers for the accounts you choose.'
  }, {
    name: 'Balances, transactions and rewards',
    description: 'This includes your balances at the beginning of the most recent business day, as well as all posted and pending transactions. Deposits, payments, fees and rewards may also be included.'
  }, {
    name: 'Account numbers (Chase will substitute these)',
    description: 'Many applications require account and routing numbers to allow transactions from eligible checking and savings accounts. To help protect you from fraud, we share "virtual account numbers" instead—they work the same as actual account numbers when used by authorized third parties. Note: It may take up to 24 hours before you can start making transactions.'
  }]
};

export { MockClient };
