/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import TAG from "../tag";

import DocumentationNavList from "../lists/DocumentationNavList";
import IcoLink from "../../images/ico-link.svg";
import ArrowDown from "../../images/arrow-down.svg";

import MockCode from "../../data/MockCode";

const sections = MockCode.map(b => b.name);

export default function CodePaneNavHeader(props) {
  const isCancelled = useRef(false);

  const [state, setState] = useState({
    isNavShown: false
  });

  const handleClick = () => {
    const visibilityFlag = !state.isNavShown;
    setState({
      ...state,
      isNavShown: visibilityFlag
    });
  };

  const handleCollapseNavHeading = () => {
    const jsButton = document.querySelector("#codeTabSwitch-0");
    const directionalButtonRight = document.querySelector(
      "#directional-button-right"
    );
    if ([directionalButtonRight, jsButton].includes(document.activeElement)) {
      if (!isCancelled.current) {
        setState({ ...state, isNavShown: false });
      }
    }
  };

  const useOutsideCloser = ref => {
    useEffect(() => {
      const handleClickOutside = e => {
        if (
          ref.current &&
          !ref.current.contains(e.target) &&
          !isCancelled.current
        ) {
          setState({ ...state, isNavShown: false });
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", e => {
        document.addEventListener("focus", handleCollapseNavHeading, true);
      });

      return () => {
        // Unbind the event listener on clean up
        isCancelled.current = true;
        document.removeEventListener("mousedown", handleClickOutside);
        document.removeEventListener(
          "keydown",
          e => {
            document.removeEventListener(
              "focus",
              handleCollapseNavHeading,
              true
            );
          },
          true
        );
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutsideCloser(wrapperRef);

  const handleClickNavSection = (e, name) => {
    if (!isCancelled.current) {
      setState({
        isNavShown: false
      });
    }

    props.onChangeSection(name);
  };

  return (
    <div ref={wrapperRef}>
      <div
        className={`row border-bottom-gray code-pane-nav-header ${
          state.isNavShown ? "expanded" : "collapsed"
        }`}
      >
        <button
          aria-label={`Code Section Selection - currently ${
            props.currentSection
          }, list ${state.isNavShown ? "expanded" : "collapsed"}`}
          data-testid={"show-nav-button"}
          className={`col bg-code-header pointer ${TAG.DYNAMIC}`}
          data-pt-name={`nav_code_drpdwn_from_${props.getDataPtName(
            props.currentSection
          )}`}
          onClick={handleClick}
        >
          <img
            className="pe-3 py-3"
            alt={`Current Section Heading - ${props.currentSection}`}
            src={IcoLink}
          />
          <h3 className="font-large align-middle d-inline">
            {props.currentSection}
          </h3>
          <img
            className="ps-3"
            alt={`Dropdown Panel ${
              state.isNavShown ? "expanded" : "collapsed"
            }`}
            src={ArrowDown}
          />
        </button>
      </div>
      {state.isNavShown && (
        <div className="code-nav-overlay bg-code-header">
          <DocumentationNavList
            getDataPtName={props.getDataPtName}
            sections={sections.filter(s => s !== props.currentSection)}
            goToSection={handleClickNavSection}
          />
        </div>
      )}
    </div>
  );
}
