/* eslint-disable */
import React from "react";

import Lock from "../../images/lock.svg";
import ChaseCircle from "../../images/chase-circle.svg";
import SunCircle from "../../images/sun-circle.svg";
import Connect from "../../images/connect.svg";
import Disconnect from "../../images/disconnect.svg";

function LogoRegion() {
  return (
    <div className="logo-container">
      <div className="logo-col-1">
        <img src={ChaseCircle} alt="Chase logo" />
        <div className="logo-label">Bank</div>
      </div>
      <div className="logo-col-2">
        <div className="logo-sub-col">
          <div role="presentation" className="logo-hr" />
        </div>
      </div>
      <div className="logo-col-1">
        <img src={SunCircle} alt="Application logo" />
        <div className="logo-label">Application</div>
      </div>
    </div>
  );
}

export default function ClientInfoScreen(props) {
  return (
    <div>
      <h4 data-testid={"howToConnect"} className="h6">
        How to connect your accounts
      </h4>
      <p className="advisory font-small">
        Review both companies' terms of use and privacy policies to learn how
        they'll use your data, or if they plan to share or sell it.
      </p>
      <LogoRegion />
      <p className="advisory font-small mb-3">
        Any time you connect with a third party, Chase will help you limit and
        manage their access:
      </p>
      <div className="mb-3 font-small">
        <img
          src={Lock}
          className="icon-mr"
          alt="Lock Graphic for Account Security"
        />
        <span className="advisory">
          Third parties won't have access to your username, password and full
          account numbers
        </span>
      </div>
      <div className="mb-3 font-small">
        <img
          src={Connect}
          className="icon-mr"
          alt="Chain Graphic for Connected Accounts"
        />
        <span className="advisory">You choose which accounts to connect</span>
      </div>
      <div className="font-small">
        <img
          src={Disconnect}
          className="icon-mr"
          alt={"Disconnected Chain Graphic for Disconnecting Accounts"}
        />
        <span className="advisory">
          You can disconnect your accounts any time
        </span>
      </div>
    </div>
  );
}
