/* eslint-disable */
import React from "react";
import TAG from "../tag";

export default function DeveloperHeader(props) {
  return (
    <nav className="navbar navbar-expand-md fixed-top bg-light border-bottom-gray">
      <a
        href="https://developer.chase.com"
        className={`navbar-brand nav-text text-uppercase ms-2 ${TAG.EXTERNAL}`}
        data-pt-name="hd_to_dev_chase_home"
      >
        <i className="fa fa-chevron-left hdr-arrow pe-1" />
        Back to Chase Developer
      </a>
    </nav>
  );
}
