/* eslint-disable */
import React, {useState} from 'react';
import TAG from '../tag';

export default function CollapsableList(props) {
  const [activeIndex, setActiveIndex] = useState(props.initialIndex);
  const toggleAtIndex = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  if (!props.items) {
    return null;
  }

  return (
    <div className="collapsable-list-container">
      {props.items.map((item, index) => {
        const isActive = index === activeIndex;
        return (
          <div key={index} data-testid={item.name}>
            <button
              role="button"
              className={`btn d-inline-flex btn-icon font-small login-gray ${TAG.DYNAMIC}`}
              data-pt-name={`lnk_prvw_disclosure_${index + 1}`}
              aria-expanded={isActive}
              onClick={() => toggleAtIndex(index)}>
              <h5 className="h6">
              {item.name}
              </h5>
            </button>
            <div
              className={`accordion-data font-small login-gray collapse${isActive ? ' show' : ''}`}>
              {item.description}
            </div>
          </div>
        );
      })}
    </div>
  );
}
