import { dropdownStyles } from "./";

const CustomOption: React.FC = ({ children, ...props }: any) => {
  const { innerProps, innerRef, isFocused, isDisabled, isSelected } = props;
  const { AATagDescriptor, value } = props.data;

  return (
    <button
      ref={innerRef}
      {...innerProps}
      style={dropdownStyles.option({}, { isFocused, isDisabled, isSelected })}
      className="react-select__option chaseanalytics-track-link"
      data-pt-name={`nav_drpdwn_${AATagDescriptor}${value.flow}`}
    >
      {children}
    </button>
  );
};

export default CustomOption;
