import React from 'react';
import LoginForm from '../forms/LoginForm';
import MobileNavBar from '../headers/MobileNavBar';
import WordmarkWhite from '../../images/wordmark-white.png';

export default function LoginScreen(props) {
  const doLogin = (e) => {
    e.preventDefault();
    props.goNext();
  };

  return (
    <div>
      <MobileNavBar url="chase.com" />
      <div className="preview-app-body row border-gray login-page" data-testid="login-page">
        <div className="container-fluid">
          <div className="row py-4">
            <div className="col text-center">
              <div className="logo">
                <img
                  src={WordmarkWhite}
                  width="180"
                alt={"Chase Logo"}/>
              </div>
            </div>
          </div>
          <div className="row pb-4">
            <div className="mx-auto col-11">
              <h3 className="hide-element">Chase Login Form</h3>
              <LoginForm
                onSubmit={doLogin} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
