/* eslint-disable */
import React, { useState } from "react";
import Flows from "../../data/Flows";

import {
  AppDashboardScreen,
  BeginOauthScreen,
  LoginScreen,
  WrappedClientInfoScreen,
  WrappedPermissionScreen,
  WrappedAccountScreen,
  WrappedReviewScreen,
  OauthConnectedScreen,
  ScrollableScreen
} from "../screens";

import CircleList from "../lists/CircleList";

const screens = [
  {
    component: AppDashboardScreen,
    name: "AppDashboardScreen",
    contentSection: "start",
    flow: Flows.GET_CONSENT
  },
  {
    component: BeginOauthScreen,
    name: "BeginOauthScreen",
    contentSection: "user-consent",
    flow: Flows.GET_CONSENT
  },
  {
    component: LoginScreen,
    name: "LoginScreen",
    contentSection: "user-consent",
    flow: Flows.GET_CONSENT
  },
  {
    component: WrappedClientInfoScreen,
    name: "WrappedClientInfoScreen",
    contentSection: "user-consent",
    flow: Flows.GET_CONSENT
  },
  {
    component: WrappedPermissionScreen,
    name: "WrappedPermissionScreen",
    contentSection: "select-accounts",
    flow: Flows.SELECT_ACCOUNTS
  },
  {
    component: WrappedAccountScreen,
    name: "WrappedAccountScreen",
    contentSection: "select-accounts",
    flow: Flows.SELECT_ACCOUNTS
  },
  {
    component: WrappedReviewScreen,
    name: "WrappedReviewScreen",
    contentSection: "confirm-accounts",
    flow: Flows.VERIFY_PAYMENT_NETWORKS
  },
  {
    component: OauthConnectedScreen,
    name: "OauthConnectedScreen",
    contentSection: "confirm-accounts",
    flow: Flows.VERIFY_PAYMENT_NETWORKS
  },
  {
    component: AppDashboardScreen,
    name: "AppDashboardScreen",
    contentSection: "demo-complete",
    flow: Flows.VERIFY_PAYMENT_NETWORKS
  }
];

const getScreen = (i, props) => {
  const { component: Screen, contentSection } = screens[i];
  return (
    <ScrollableScreen contentSection={contentSection}>
      <Screen {...props} />
    </ScrollableScreen>
  );
};

export default function PreviewPane(props) {
  const [screenIndex, setScreenIndex] = useState(0);

  const goToScreen = index => {
    const nextIndex = Math.max(0, Math.min(index, screens.length - 1));
    if (nextIndex !== screenIndex) {
      setScreenIndex(nextIndex);
    }
  };

  return (
    <section
      title="Interactive App Demo"
      className="col-xl-3 col-lg-5 col-md-12 border-right-gray bg-light-contrast preview-pane overflow-auto"
    >
      <h2 className={"hide-element"}>Interactive App Demo</h2>
      <div className="pt-128 preview-app px-5 remove-padding-mobile ">
        {getScreen(screenIndex, {
          index: screenIndex,
          numScreens: screens.length,
          onUpdateUser: props.onUpdateUser,
          isConnected: screenIndex + 1 === screens.length,
          selectedAccounts: props.selectedAccounts,
          goNext: () => {
            goToScreen(screenIndex + 1);
          },
          goBack: () => {
            goToScreen(screenIndex - 1);
          }
        })}
      </div>
      <div className="py-3 w-100">
        <div className="d-flex w-100 mx-auto">
          <CircleList
            screens={screens}
            numItems={screens.length}
            currentIndex={screenIndex}
            onClick={goToScreen}
          />
        </div>
      </div>
    </section>
  );
}
