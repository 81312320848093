const regMark = String.fromCharCode(174)
const MockAccountOwner = {
  accounts: [{
    name: 'Checking (...1221)',
    balance: 10000.00
  }, {
    name: `Sapphire ${regMark} (...5689)`,
    balance: -501.23
  }, {
    name: 'Savings (...0654)',
    balance: 4021.00
  }]
};

export { MockAccountOwner };
