
import { Helmet } from "react-helmet";


const ADSHead = () => {

  return (
    <Helmet>
        <title>Account Data Sharing API Demo | Chase Developer</title>
        <meta
          name="description"
          content=" Explore this demo to see how you can enable your customers to link their Chase accounts to your third-party app. This demo is intended for developers in a partnership with Chase."
        />
    </Helmet>
  )
}


export default ADSHead