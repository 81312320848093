import {ReactNode, useState, createContext, useContext, ComponentType, FC} from 'react';

import {DARK_THEME, getCurrentTheme, LIGHT_THEME, setCurrentTheme} from './Theme';

export type ThemeContextProps = {
  theme: string;
  toggleTheme: () => void;
  isLightTheme: boolean;
  isDarkTheme: boolean;
};

type ThemeProviderProps = {
  children?: ReactNode;
}

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

const ThemeContext = createContext<ThemeContextProps>({} as ThemeContextProps);

export function ThemeProvider({children}: ThemeProviderProps) {
  const [theme, setTheme] = useState(getCurrentTheme());

  function toggleTheme() {
    const t = theme === DARK_THEME ? LIGHT_THEME : DARK_THEME;
    setCurrentTheme(t);
    setTheme(t);
  }

  const isLightTheme = theme === LIGHT_THEME;
  const isDarkTheme = theme === DARK_THEME;

  return (
    <ThemeContext.Provider value={{theme, toggleTheme, isLightTheme, isDarkTheme}}>
      {children}
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
}

export function withTheme<P extends Record<string, unknown>>(WrappedComponent: ComponentType<P>): FC<Omit<P, keyof ThemeContextProps>> {
  // eslint-disable-next-line react/display-name
  return (props) => {
    return (
      <ThemeContext.Consumer>
        {({theme, toggleTheme, isLightTheme, isDarkTheme}) => (
          <WrappedComponent
            {...props as P}
            theme={theme}
            toggleTheme={toggleTheme}
            isLightTheme={isLightTheme}
            isDarkTheme={isDarkTheme}
          />
        )}
      </ThemeContext.Consumer>
    );
  };
}
