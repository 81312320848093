import React from 'react';
import TAG from "../tag"
import DemoDirectionalSVG from '../../images/demo-directional.svg';


export default function DemoDirectional(props: any){



  return (
    <div>

      <button
        className={`directional-button ${TAG.DYNAMIC} ${props.direction === "right" ? "flip-x" : ""}` }
        id={`directional-button-${props.direction}`}
        data-pt-name={`lnk_prvw_dir_${props.direction}`}
        title={`Directional Button ${props.direction === "left" ? "Back" : "Forward"}`}
        onClick={props.handleDirectionalClick} >
        <img
          data-testid="button-image"
          alt={`Directional Button ${props.direction === "left" ? "Back" : "Forward"}`}
          src={DemoDirectionalSVG}
          aria-label={`Directional Button ${props.direction === "left" ? "Back" : "Forward"}`}
        />
      </button>
    </div>
  )




}
