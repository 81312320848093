import React, {useContext} from 'react';

import UserContext from '../UserContext';
import ClientInfoScreen from './ClientInfoScreen';
import MobileNavBar from '../headers/MobileNavBar';
import ConsentFlowHeader from '../headers/ConsentFlowHeader';
import StatusBarCompletion from '../status/StatusBarCompletion';
import MobileNavControls from '../controls/MobileNavControls';

export default function WrappedClientInfoScreen(props) {
  const userContext = useContext(UserContext);
  return (
    <div>
      <MobileNavBar url="chase.com" />
      <div className="preview-app-body row border-gray">
        <div className="container-fluid">
          <ConsentFlowHeader />
          <StatusBarCompletion
            numSteps={4}
            currentIndex={0} />
          <div className="row py-2">
            <div className="col-sm-10 offset-sm-1">
              <ClientInfoScreen
                client={userContext.client} />
            </div>
          </div>
          <MobileNavControls
            scrollCentralPane={props.scrollCentralPane}
            section='user-consent'
            goBack={props.goBack}
            goNext={props.goNext}
            dataPtName={"client_info"}
            nextButtonText="Next" />
        </div>
      </div>
    </div>
  );
}
