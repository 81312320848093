/* eslint-disable */
import React, { useState, useEffect } from 'react';
import TAG from '../tag';

import FUTURE_ACCOUNT_STR from '../../data/FutureAccountString';

function Checkbox(props) {

  const labelClasses = `custom-control-label font-small login-gray \
    ${(props.strong && 'fw-bold') || ''}\
    ${(props.normalCapitalization && ' text-uppercase') || ''}`;

  return (
    <div className="custom-control custom-checkbox">
      <input
        type="checkbox"
        className={`custom-control-input me-1 ${TAG.FORM}`}
        data-pt-name={`prvw_acc_select_${props.dataPtName}`}
        checked={props.checked || false}
        data-testid={`selectAccount-${props.name}`}
        name={props.name}
        id={props.id}
        onChange={props.onClick} />
      <label
        className={labelClasses}
        htmlFor={props.id}>
        {props.name}
      </label>
    </div>
  );
}

export default function AccountSelectionForm(props) {

  const [allSelected, setAllSelected] = useState(props.selectedAccounts.length - 1 === props.user.accounts.length);

    useEffect (()=>{
       setAllSelected(props.selectedAccounts.length - 1 === props.user.accounts.length ? true : false)
    }, [props.selectedAccounts.length])

  const isChecked = (elem) => {
    const match = props.selectedAccounts.find(e => e.name === elem);
    return match !== undefined;
  };

  const handleClickAll = (e) => {
    allSelected ? props.setSelectedAccounts([]) : props.setSelectedAccounts(props.user.accounts.concat({name: FUTURE_ACCOUNT_STR}))
  };

  const handleClick = (e) => {
    const { name, checked } = e.target;


    if (checked) {
      const checkedAccount = props.user.accounts.find(a => a.name === name) || {name: FUTURE_ACCOUNT_STR};
      const newAccounts = [...props.selectedAccounts, checkedAccount];
      props.setSelectedAccounts(newAccounts);
    } else {
      props.setSelectedAccounts(props.selectedAccounts.filter((a) => a.name !== name ));
    }

  };



  return (
    <form className="form-group" aria-label="Accounts to be shared">
      <Checkbox
        key={0}
        id="checkAllAccounts"
        dataPtName="all"
        aria-label="Select all accounts"
        name="Select all accounts"
        onClick={handleClickAll}
        checked={allSelected}
        strong
        normalCapitalization />
      {props.user.accounts.map((account, index) => (
        <Checkbox
          key={index + 1}
          id={`checkAccount${index}`}
          dataPtName={index + 1}
          name={account.name}
          aria-label={account.name}
          onClick={handleClick}
          checked={isChecked(account.name)} />
      ))}
      <Checkbox
        key={props.user.accounts.length + 1}
        id="checkFutureAccounts"
        dataPtName="future"
        name={FUTURE_ACCOUNT_STR}
        aria-label={FUTURE_ACCOUNT_STR}
        onClick={handleClick}
        checked={isChecked(FUTURE_ACCOUNT_STR)}
        normalCapitalization />
    </form>
  );
}
