import jsCookie from 'js-cookie';

export const DARK_THEME = 'dark';
export const LIGHT_THEME = 'light';

export function setCurrentTheme(theme: string) {
  document.documentElement.className = theme;
  jsCookie.set('moneta-theme', theme, {expires: 365});
}

export function getCurrentTheme() {
  let theme;
  let preferenceTheme = jsCookie.get('moneta-theme');
  const documentTheme = document.documentElement.className;
  preferenceTheme = (preferenceTheme === LIGHT_THEME || preferenceTheme === DARK_THEME) ? preferenceTheme : undefined;

  if (preferenceTheme) {
    theme = preferenceTheme;
    if (preferenceTheme !== documentTheme) {
      setCurrentTheme(preferenceTheme);
    }
  } else {
    theme = documentTheme;
  }
  return theme;
}
