/* eslint-disable */
import React, { useContext, useRef } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

import Flows from "../../data/Flows";
import UserContext from "../UserContext";
import LoggedInContent from "../content/LoggedInContent";
import LoggedOutContent from "../content/LoggedOutContent";

import { Link } from "react-router-dom";

const provideContent = props => {
  return props.isLoggedIn ? <LoggedInContent /> : <LoggedOutContent />;
};

export default function CentralPane(props) {
  const userContext = useContext(UserContext);
  const selfRef = useRef();
  const containerRef = useRef();
  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y < 1900) {
        props.updateFlow(Flows.GET_CONSENT);
      } else if (currPos.y >= 1900 && currPos.y < 2700) {
        props.updateFlow(Flows.SELECT_ACCOUNTS);
      } else if (currPos.y >= 2700) {
        props.updateFlow(Flows.VERIFY_PAYMENT_NETWORKS);
      }
    },
    [userContext],
    selfRef,
    false,
    50,
    containerRef
  );

  return (
    <section
      title="Documentation Pane"
      className="col-xl-5 col-lg-7 col-md-7 border-right-gray mt-4 h-100 ads-documentation-pane"
    >
      <div tabIndex="0" className="overflow-auto pb-5" ref={containerRef}>
        <div className="mx-3 developer-font" ref={selfRef}>
          {provideContent(props)}
        </div>
      </div>
    </section>
  );
}
