import React from "react";

const NavBar: React.FC = () => {
  return (
    <nav className="sc-navbar" data-testid="back-to-chase-dev-navbar">
      <div className="navbar-left">
        <a
          href="https://developer.chase.com"
          className={`sc-navbar-text chaseanalytics-opt-exlnk`}
          data-pt-name="hd_to_dev_chase_home"
        >
          <i className="fa fa-chevron-left hdr-arrow" />
          Back to Chase Developer
        </a>
      </div>
    </nav>
  );
};

export default NavBar;
