import React, {useContext} from 'react';

import MobileNavBar from '../headers/MobileNavBar';
import MobileNavControls from '../controls/MobileNavControls';
import {AccountListGroup} from '../lists/AccountListGroup';
import UserContext from '../UserContext';

import {CLIENT_URL_STR} from '../../data/ClientUrl';
import FUTURE_ACCOUNT_STR from '../../data/FutureAccountString';

export default function OauthConnectedScreen(props) {
  const userContext = useContext(UserContext);
  const nonEligibleAccounts = userContext.user.accounts.filter(a => {
    const match = userContext.selectedAccounts.find(s => s.name === a.name);
    return match === undefined;
  }) || [];
  return (
    <div>
      <MobileNavBar url={CLIENT_URL_STR} />
      <div className="preview-app-body row border-gray">
        <div className="col-12">
          <div className="my-4">
            <h3 className="preview-font-header">
              Confirm Accounts
            </h3>
          </div>
          <p className="pb-2 font-small">
            Select one or more accounts to use in this application
          </p>
          <h4 className="h6 fw-bold">Accounts you can choose</h4>
          <div className="pt-2 pb-4 w-100">
            <div className="mx-2">
              <AccountListGroup
                accounts={userContext.selectedAccounts.filter(a => a.name !== FUTURE_ACCOUNT_STR)}
                onUpdateUser={props.onUpdateUser} />
            </div>
          </div>
          {nonEligibleAccounts.length > 0 &&
          <>
            <h4 className="h6 fw-bold">
              Accounts not eligible
            </h4>
            <div className="pt-2 pb-4 w-100">
              <div className="mx-2">
                <AccountListGroup
                  accounts={nonEligibleAccounts}
                  disabled />
              </div>
            </div>
          </>}
          <MobileNavControls
            goBack={props.goBack}
            goNext={props.goNext}
            dataPtName="oauth_conn"
            nextButtonText="Confirm"
            isPartnerSite />
        </div>
      </div>
    </div>
  );
}
