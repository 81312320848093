const dropdownStyles = {
  valueContainer: (base: any) => ({
    ...base,
    padding: "16px",
    color: "white",
    paddingLeft: "42px",
    fontSize: "18px",
    background: 'none',
    lineHeight: "19px",
    flex: "unset",
    display: "flex",
    border: "none",
    textAlign: 'left'
  }),
  control: (base: any) => ({
    ...base,
    backgroundColor: "#152748",
    color: "white",
    border: "initial",
    borderBottom: "1px solid white",
    justifyContent: "flex-start",
    flexWrap: "initial",
    cursor: "pointer",
  }),
  option: (base: any, { isDisabled, isFocused, isSelected }: any) => ({
    ...base,
   textAlign: "left", 
    paddingLeft: "24px",
    backgroundColor: isDisabled
      ? "#fff000"
      : isFocused
      ? "#0074C7"
      : "#152748",
    color: "white",
    cursor: "pointer",
    border: isFocused ? "1px dashed white" : '1px solid transparent',
    "&:active": {
      backgroundColor: "#257AFD",
    }
  }),
  indicatorsContainer: () => ({
    ".react-select": {
      "&__dropdown-indicator": {
        color: "white"
      }
    }
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0
  }),
  menu: (base: any) => ({
    ...base,
    boxShadow: "none",
  }),
  singleValue: (base: any) => ({
    ...base,
    textOverflow: "initial",
    overflow: "initial",
    lineHeight: "normal",
    backgroundColor: "none",
    color: "white",
    whiteSpace: "initial"
  })
};

export default dropdownStyles
