/* eslint-disable */
import React from 'react';

import MobileNavBar from '../headers/MobileNavBar';
import MobileNavControls from '../controls/MobileNavControls';

import SunPartner from '../../images/sun-partner.png';
import Clip from '../../images/clip.png';
import { CLIENT_URL_STR } from '../../data/ClientUrl';

export default function BeginOauthScreen(props) {
  return (
    <div>
      <MobileNavBar url={CLIENT_URL_STR} />
      <div className="preview-app-body row border-gray" data-testid="begin-o-auth-screen">
        <div className="col-12">
          <div className="my-4">
            <h3 className="preview-font-header">
              Provide Consent
            </h3>
          </div>
          <h4 className="h6">Give this application permission to access your Chase accounts</h4>
          <p className="pb-2 font-small">You're about to be redirected to Chase's secure login site, where you can sign in and connect your accounts. Once your accounts are connected, you'll return here, and you can select the accounts you want to link.</p>
          <div className="row py-4">
            <div className="col-6 text-right">
              <img src={SunPartner} />
            </div>
            <div className="col-5 text-left ms-2">
              <img className="clipboard-pt" src={Clip} />
            </div>
          </div>
          <MobileNavControls
            scrollCentralPane={props.scrollCentralPane}
            section="user-consent"
            goBack={props.goBack}
            goNext={props.goNext}
            dataPtName="begin_oauth"
            nextButtonText="Continue"
            isPartnerSite />
        </div>
      </div>
    </div>
  );
}
