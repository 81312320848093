import React from 'react';

export default function DeveloperCard(props) {
  let baseClass = 'card bg-light-contrast border-gray border-rounded mb-4';
  if (props.borderLeft) {
    baseClass += ' developer-card-bl';
  }

  return (
    <div
      className={baseClass}>
      <div className="card-body developer-card-body">
        {props.children}
      </div>
    </div>
  );
}
