/* eslint-disable */
import React from 'react';
import TAG from '../tag';
import DemoDirectional from '../controls/DemoDirectional';

export default function CircleList(props) {
  if (!props.numItems) {
    return null;
  }

  const handleDirectionalClick = ( direction) => {
    props.onClick(props.currentIndex +
      (direction === "left"? -1 : 1)
    )
  }


  const sanitizeName = i => {
    return props.screens[i].name.slice(0, 7) === "Wrapped" ? props.screens[i].name.slice(7) : props.screens[i].name
  }

  const circles = [...Array(props.numItems).keys()].map(n => {
    let classNames = 'circle-li';
    if (props.currentIndex === n) {
      classNames += ' circle-li-active';
    }

    return (
      <li key={n} className={classNames + " " + sanitizeName(n) }>
        <button
          title={`Button ${n + 1}: ${sanitizeName(n).replace(/([A-Z])/g, ' $1').trim()}`}
            className={`${TAG.DYNAMIC} circle-list-button`}
          data-pt-name={`lnk_prvw_circle_${n+1}`}
          id={`lnk_prvw_circle_${n+1}`}
          role="button"
          data-testid={`circle-button-${n + 1}`}
          aria-label={`Button ${n + 1}: ${sanitizeName(n).replace(/([A-Z])/g, ' $1').trim()}`} // adds whitespace before capital letter
          onClick={() => props.onClick(n)}>
          {n}
        </button>
      </li>
    );
  });

  return (
    <>
    <DemoDirectional direction="left"  handleDirectionalClick={()=>handleDirectionalClick("left")}  />
    <ul className="circle-list">
      {circles}
    </ul>
      <DemoDirectional direction="right"  handleDirectionalClick={()=>handleDirectionalClick("right")} />
      </>
  );
}
