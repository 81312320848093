import { ReactComponent as IcoLink } from "../../../../images/ico-link.svg";
import { dropdownStyles } from "./";
const ValueContainer: React.FC = ({ children, ...props }: any) => {
  const { innerProps, innerRef } = props;
  return (
    <button
      ref={innerRef}
      {...innerProps}
      className="react-select__value-container chaseanalytics-track-link"
      data-pt-name="nav_toggle_dropdown"
      style={dropdownStyles.valueContainer({})}
    >
      {!!children && <IcoLink style={{ position: "absolute", left: 12 }} />}
      {children}
    </button>
  );
};

export default ValueContainer;
