import React from 'react';

export default function PlainList(props) {
  if (!props.items) {
    return null;
  }

  return (
    <ul className="plain-list" aria-label={props.ariaLabel} data-testid='plain-list-ul'>
      {props.items.map((item, index) => (
        <li
          key={index}
          className="hide-overflow">
          {item}
        </li>
      ))}
    </ul>
  );
}
