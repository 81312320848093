import React from 'react';

const ReadyToBuild: React.FC = () => {
  /* HTML rendering of Ready-to-build section */
  return (
    <>
      <div className="fake-image-grouping">
        <div className="rtb-side-companion preview-pane-container"></div>
        <div
          data-testid="rtb-container"
          className="text-grouping rtb-container"
        >
          <div className="ready-to-build">
            <h2>Ready to build your solution?</h2>
            <p>
              If you’re already a partner, sign in to the Developer Portal. If
              you aren’t a partner, contact us to request access.
            </p>
            <div className="rtb-doc-links">
              <a
                href="https://developer.chase.com/login"
                className="chaseanalytics-track-element secondary-action-button"
                data-pt-name="rs_central_signin"
              >
                Sign in
              </a>
              <a
                href="https://developer.chase.com/register/"
                className="chaseanalytics-track-element action-button"
                data-pt-name="rs_central_register"
              >
                Create account
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReadyToBuild
