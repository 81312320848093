import React from "react";

const NextButton: React.FC = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 20.6613C16.112 20.6613 20.6613 16.1119 20.6613 10.5C20.6613 4.88807 16.112 0.338707 10.5 0.338707C4.88811 0.338707 0.338745 4.88807 0.338745 10.5C0.338745 16.1119 4.88811 20.6613 10.5 20.6613Z"
        className="next-btn-circle"
        strokeWidth="0.677419"
      />
      <path
        d="M13.8711 10.3052C13.8713 10.1282 13.8022 9.95841 13.6791 9.83335L9.07415 5.14634C8.90988 4.97099 8.66519 4.89997 8.43473 4.96081C8.20434 5.02165 8.02442 5.20478 7.96467 5.43927C7.90492 5.67376 7.97477 5.92276 8.14717 6.08983L12.2878 10.3039L8.14717 14.518C7.97673 14.6855 7.90831 14.9336 7.96833 15.1669C8.02835 15.4002 8.20739 15.5824 8.4367 15.6434C8.66594 15.7045 8.90974 15.635 9.07442 15.4615L13.6788 10.7769C13.8022 10.6521 13.8714 10.4823 13.8711 10.3052Z"
        fill="#666666"
      />
    </svg>
  );
};

export default NextButton;
