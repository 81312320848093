import React from 'react';

import CodePaneNavHeader from '../controls/CodePaneNavHeader';
import TabbedCodeView from '../code-view/TabbedCodeView';
import MockCode from '../../data/MockCode';


export default function CodePane(props) {

  const section = MockCode[props.flow];

  const handleChangeSection = (name) => {
    props.updateFlow(MockCode.findIndex(b => b.name === name));
  };


  const getDataPtName = (name) => {
    return name.split(" ").map(w => w[0].toLowerCase()).join("");
  }

  
  return (


    <section title="Code Example for Consuming API" className="col-xl-4 col-lg-12 col-md-5 bg-code ads-code-pane no-x-gutter">
      <h2 className={"hide-element"}>Code Example for Consuming API</h2>
      <CodePaneNavHeader
        currentSection={section.name}
        getDataPtName={getDataPtName}
        sections={MockCode}
        onChangeSection={handleChangeSection} />
      <TabbedCodeView
        tabs={section.code}
        dataPtName={getDataPtName(section.name)}/>
    </section>

  );
}
