import React, {useState, useContext} from 'react';
import TAG from '../tag';

import UserContext from '../UserContext';

const Checkbox = (props)=> {
  const htmlId = `checkboxAccount${props.account}`;
  return (
    <div className="custom-control custom-checkbox float-left">
      <input
        type="checkbox"
        className={`custom-control-input me-1 ${TAG.FORM}`}
        data-pt-name={`prvw_oauth_conn_${props.dataPtName}`}
        id={htmlId}
        name={htmlId.slice(15)}
        checked={props.checked || false}
        onChange={props.onChange} />
      <label
        className="custom-control-label"
        htmlFor={htmlId}>
        {props.account}
      </label>
    </div>
  );
}

const AccountListGroup = (props) => {
  const userContext = useContext(UserContext);
  const [accounts, setAccounts] = useState(props.accounts.map(a => {
    return {
      ...a,
      selected: false
    };
  }));

  const handleChange = (e, index) => {
    const newAccounts = Object.assign([], accounts);
    const account = newAccounts[index];
    account.selected = e.target.checked;
    setAccounts(newAccounts);
    props.onUpdateUser({
      ...userContext,
      connectedAccounts: newAccounts.filter(a => a.selected)
    });
  };

  return (
    <ul className="list-group w-100">
      {accounts.map((account, index) => (
        <li
          key={index}
          className="list-group-item px-2">
          {props.disabled &&
            <span>{account.name}</span>}
          {!props.disabled &&
            <div className="clearfix">
              <Checkbox
                dataPtName={index + 1}
                account={account.name}
                checked={account.selected}
                onChange={(e) => handleChange(e, index)} />
              <span className="float-right">
                {account.balance.toLocaleString('en-US', {style: 'currency', currency: 'USD'})}
              </span>
            </div>}
        </li>
      ))}
    </ul>
  );
}

export { AccountListGroup, Checkbox }
