/* eslint-disable */
import React, {useContext} from 'react';
import UserContext from '../UserContext';
import MobileNavBar from '../headers/MobileNavBar';
import ConnectedAccountList from '../lists/ConnectedAccountList';
import { CLIENT_URL_STR } from '../../data/ClientUrl';
import TAG from '../tag';

import IconActivityDarkRed from '../../images/icon-activity-dark-red.svg';
import IconActivity from '../../images/icon-activity.png';

export default function AppDashboardScreen(props) {
  const userContext = useContext(UserContext);

  const activityIcon = props.isConnected ? IconActivityDarkRed : IconActivity;

  return (
    <div>
      <MobileNavBar url={CLIENT_URL_STR} />
      <div className="preview-app-body row border-gray">
        <div className="col-12">
          <div className="my-4">
            <h3 className="preview-font-header">
              Dashboard
            </h3>
          </div>
          {!props.isConnected &&
            <div className="oauth-connect-shadow">
              <button
                title="Begin Interactive Demo - Set Up Payments"
                className={`oauth-connect-button ${TAG.DYNAMIC}`}
                data-pt-name="lnk_prvw_dashboard_nxt"
                onClick={props.goNext}>
                  <div className="mx-4 py-4">
                    <h4 className="text-uppercase h6 set-up-payments" >
                      Set up payments
                    </h4>
                    <p className="mb-0 pb-0">
                      Give consent to verify and link your Chase accounts
                    </p>
                  </div>
              </button>
            </div>}
          <div className="py-4">
            <div className="d-inline-block">
              <img src={activityIcon} alt="Activity" />
              <h4 className='preview-subheading d-inline'>Linked accounts</h4>
            </div>
          </div>
          <div className="pt-3 pb-5 mx-2 bg-white">
            {props.isConnected &&
              <div>
                <ConnectedAccountList
                  accounts={userContext.connectedAccounts} />
                <div className="br-developer my-2 mx-3" />
                <div className="text-center">
                  <a
                    href="#"
                    className={`font-small link-primary text-decoration-none ${TAG.DYNAMIC}`}
                    data-pt-name="lnk_prvw_dashboard_manage"
                    onClick={props.goBack}>
                    + Add or manage accounts
                  </a>
                </div>
              </div>}
            {!props.isConnected &&
              <div className="text-center">
                <p className="preview-gray preview-body pb-0 mb-0">
                  You currently have no linked accounts.
                </p>
                <p className="preview-gray preview-body">
                  Connect with Chase to link your accounts.
                </p>
              </div>}
          </div>
        </div>
      </div>
    </div>
  );
}
