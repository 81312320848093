/* eslint-disable */
import React from 'react';
import TAG from '../tag';



export default function   DocumentationNavList(props) {
  return (
    <ul className="list-group bg-code-header code-nav-list" data-testid='code-nav-list'>
      {props.sections.map((elem, index) => (
        <li
          key={index}
          className="list-group-item bg-code-header border-0">
          <a
            href="#"
            className={`text-white ps-4 text-no-underline ${TAG.DYNAMIC}`}
            data-pt-name={`nav_code_drpdwn_to_${props.getDataPtName(elem)}`}
            aria-label={props.sections[index]}
            data-testid={`documentation-nav-list-entry-${index}`}
            onClick={(e) => {
              e.preventDefault();
              props.goToSection(e, elem);
              return false;
            }}>
            {elem}
          </a>
        </li>
      ))}
    </ul>
  );
}
