/* eslint-disable */
import React, {useState } from 'react';
import CodeView from './CodeView';
import TAG from '../tag';

export default function TabbedCodeView(props) {
  const [index, setIndex] = useState(0);

  if (!props.tabs) {
    return null;
  }

  const handleClickTab = (e, idx) => {
    e.preventDefault();
    setIndex(idx);
    return false;
  };

  const shortenLanguage = (language) => {
    return language === 'javascript' ? 'js' : 'yaml';
  }

  const tab = props.tabs[index];

  return (
    <div className="tabbed-code-view w-100 mvh-100 no-x-gutter" >
      <div className="row mb-1 no-x-gutter">
        <ul className="col nav nav-tabs border-none bg-code-header">
          {props.tabs.map((currentTab, idx) => (
            <li
              key={idx}
              className="nav-item">
              <a
                href="#"
                className={`nav-link nav-link-code${idx === index ? ' active' : ''} ${TAG.DYNAMIC}`}
                data-pt-name={`lnk_code_${props.dataPtName}_${shortenLanguage(currentTab.language)}`}
                id={`codeTabSwitch-${idx}`}
                // aria-labelledby={tab.name}
                onClick={(e) => handleClickTab(e, idx)}>
                {currentTab.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <CodeView
        dataPtName={props.dataPtName}
        language={tab.language}
        shortenLanguage={shortenLanguage}
        code={tab.code} />
    </div>
  );
}
