import React, { useContext } from 'react';
import UserContext from '../UserContext';

export default function StatusBarCompletion(props) {
  // Props are { numSteps: int, currentIndex: int }
  const steps = [...Array(props.numSteps).keys()].map(n => {
    let classNames = 'progress-steps ';
    classNames += (n <= props.currentIndex) ? 'current' : 'unvisited';
    return <div key={n} className={classNames} title={`Connecting Accounts: Step ${props.currentIndex + 1} of ${props.numSteps}`}/>;
  });

  const { client } = useContext(UserContext)

  return (
    <div className="row py-3">
      <div className="col-12 col-sm-6 offset-sm-3">
        <div className="progress-steps-wrapper mx-3">
          <h3 className="hide-element">{`How to connect Chase to ${client.name}: Step ${props.currentIndex + 1} of ${props.numSteps}`}</h3>
          {steps}
        </div>
      </div>
    </div>
  );
}
