import React, {useState} from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import TAG from '../tag';

import ChaseTheme from './ChaseTheme';

export default function CodeView(props) {

  const COPY_TO_CLIPBOARD = "Copy to clipboard"
  const COPIED = 'Copied'
  const [copyToolTip, setCopyToolTip] = useState(COPY_TO_CLIPBOARD)

  const handleCopyCode = () => {
    window.navigator.clipboard.writeText(props.code || '');
    setCopyToolTip(COPIED)
    setTimeout(()=> {
      setCopyToolTip(COPY_TO_CLIPBOARD)
    }, 2000)
  };


  return (
    <div className="w-100 px-2">
      <div className="row mb-2 flex-row-reverse">

        <button
          data-testid="copy-to-clipboard"
          className={`btn btn-copy me-2 ${TAG.DYNAMIC} copy-button`}
          data-pt-name={`lnk_code_copy_${props.dataPtName}_${props.shortenLanguage(props.language)}`}
          aria-label="Copy to clipboard"
          onClick={handleCopyCode}
        />
        <span
          className="copy-tooltip-text btn btn-secondary me-2"
          data-toggle="tooltip"
          data-placement="top"><small data-testid='copy-tooltip-text' id="copy-tooltip-text">{copyToolTip}</small>
        </span>
      </div>
      <SyntaxHighlighter
        language={props.language}
        style={ChaseTheme}
        showLineNumbers
        wrapLongLines
        lineProps={{ style: { flexWrap: 'wrap', wordBreak: 'break-all' } }}>
        {props.code}
      </SyntaxHighlighter>
    </div>
  );
}
