/* eslint-disable */
import React, {useEffect} from 'react';

import LoginForm from '../forms/LoginForm';

import WordmarkWhite from '../../images/wordmark-white.png';

export default function LoginPage(props) {
  useEffect(() => {
    document.body.classList.add('login-page');
    document.title = 'Log in with Chase';
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    props.history.push('/consent/client-info');
  };

  return (
    <div className="container-fluid">
      <div className="row py-4 my-4">
        <div className="col text-center">
          <div className="logo">
            <img height="56.09" src={WordmarkWhite} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="mx-auto col-sm-6 col-lg-4">
          <LoginForm
            onSubmit={handleLogin} />
        </div>
      </div>
    </div>
  );
}
