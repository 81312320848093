import React from 'react';

export default function ApiOperationCard(props) {
  return (
    <div className="card dark bg-code-header border-rounded mb-4 mt-4">
      <div className="api-operation-card-body card-body">
        {props.resource}
      </div>
    </div>
  );
}
