/* eslint-disable */
export default {
  linenumber: {
    color: 'white',
    minWidth: '2.25em'
  },
  code:{
    whiteSpace: "pre-wrap"
  },
  hljs: {
    display: 'block',
    overflowX: 'auto',
    padding: '0.5em',
    background: '#233659',
    color: '#f7f7f7'
  },
  'hljs-subst': {
    color: '#f7f7f7'
  },
  'hljs-tag': {
    color: '#f7f7f7'
  },
  'hljs-title': {
    color: '#f7f7f7'
  },
  'hljs-strong': {
    color: '#f7f7f7'
  },
  'hljs-emphasis': {
    color: '#f7f7f7',
    fontStyle: 'italic'
  },
  'hljs-bullet': {
    color: '#f9ed7b'
  },
  'hljs-quote': {
    color: '#f9ed7b'
  },
  'hljs-number': {
    color: '#f9ed7b'
  },
  'hljs-regexp': {
    color: '#f9ed7b'
  },
  'hljs-literal': {
    color: '#f9ed7b'
  },
  'hljs-code\n.hljs-selector-class': {
    color: '#f9ed7b'
  },
  'hljs-stronge': {
    fontStyle: 'italic'
  },
  'hljs-type': {
    fontStyle: 'italic',
    color: '#f9ed7b'
  },
  'hljs-keyword': {
    color: '#ffa85c'
  },
  'hljs-selector-tag': {
    color: '#ffa85c'
  },
  'hljs-function': {
    color: '#ffa85c'
  },
  'hljs-section': {
    color: '#ffa85c'
  },
  'hljs-symbol': {
    color: '#ffa85c'
  },
  'hljs-name': {
    color: '#ffa85c'
  },
  'hljs-attr': {
    color: '#ffa85c'
  },
  'hljs-attribute': {
    color: '#ff5555'
  },
  'hljs-variable': {
    color: '#f9ed7b'
  },
  'hljs-params': {
    color: '#f9ed7b'
  },
  'hljs-class .hljs-title': {
    color: '#f9ed7b'
  },
  'hljs-string': {
    color: '#a1dfe2'
  },
  'hljs-selector-id': {
    color: '#f9ed7b'
  },
  'hljs-selector-attr': {
    color: '#f9ed7b'
  },
  'hljs-selector-pseudo': {
    color: '#f9ed7b'
  },
  'hljs-built_in': {
    color: '#f9ed7b'
  },
  'hljs-builtin-name': {
    color: '#f9ed7b'
  },
  'hljs-template-tag': {
    color: '#f9ed7b'
  },
  'hljs-template-variable': {
    color: '#f9ed7b'
  },
  'hljs-addition': {
    color: '#f9ed7b'
  },
  'hljs-link': {
    color: '#f9ed7b'
  },
  'hljs-comment': {
    color: '#a1dfe2'
  },
  'hljs-meta': {
    color: '#a1dfe2'
  },
  'hljs-deletion': {
    color: '#a1dfe2'
  }
};
