/* eslint-disable */
import React from 'react';
import TAG from '../tag';
import PlainList from '../lists/PlainList';

export default function ReviewFinalScreen(props) {
  const onClickGoBack = (e) => {
    e.preventDefault();
    props.goBack();
  };

  return (
    <div className="mx-3">
      <h4 className="h6">Review and connect</h4>
      <h5 className="advisory font-small fw-bold">
        Accounts you chose to connect
      </h5>
      <PlainList ariaLabel={`Chosen Accounts`} items={props.selectedAccounts.map(a => a.name.toUpperCase())}/>
      <p className="py-3">
        <a
          href="#"
          className={`font-small text-underline ${TAG.DYNAMIC}`}
          data-pt-name="lnk_prvw_review_chng_acc"
          onClick={onClickGoBack}>
          Change Account Choices
        </a>
      </p>
      <h5 className="advisory font-small fw-bold">
        Information you're agreeing to share
      </h5>
      <PlainList ariaLabel={`Info to be shared with ${props.client.name}`} items={props.client.permissions.map(e => e.name)} />
      <p className="advisory font-small pt-2">
        By choosing "Connect" you consent to share this information. We'll sign you out of Chase and return you back here.
      </p>
    </div>
  );
}
