/* eslint-disable */
import React from "react";
import TAG from "../tag";
import ApiOperationCard from "../cards/ApiOperationCard";
import DeveloperCard from "../cards/DeveloperCard";

export default function LoggedOutContent(props) {
  return (
    <div id="ads-documentation-content">
      <h1 className="py-1 developer-heading" id="start">
        Share Chase account data with third-party apps
      </h1>
      <p>
        In this interactive demo, we’ll step you through a process for enabling
        your customers to link their Chase accounts to your third-party app. The
        intended audience for this demo is third-party developers who have an
        existing partnership with Chase.
      </p>
      <p>This demo is divided into three parts:</p>
      <DeveloperCard>
        <h2 className="fw-bold match-to-p">
          Get user consent for sharing data between Chase and a third-party app
        </h2>
        <p>
          Automate the process of getting consent from Chase customers to share
          their account data. This uses the User Consent API.
        </p>
        <h2 className="fw-bold match-to-p">
          Allow customers to select which Chase accounts to use within a
          third-party app
        </h2>
        <p>
          Display the customer's Chase accounts that are available to be linked
          to your app (via access token), and enable the customer to select only
          those specific accounts they want to use. This uses the User Consent
          and Account and Customer Information APIs.
        </p>
        <h2 className="fw-bold match-to-p">
          Confirm which accounts have been successfully linked to a third-party
          app
        </h2>
        <p>
          Verify with Chase that the customer's selected accounts are eligible
          to be linked to your app, and display these accounts to the customer.
          This uses the Account and Customer Information API.
        </p>
      </DeveloperCard>
      <div className="br-developer mb-5" />

      <h2 id="user-consent" className="mb-5 pt-1 developer-subheading">
        Get user consent
      </h2>
      <p>
        The first part of this demo involves getting consent from customers to
        allow data sharing between Chase and the third-party app.
      </p>
      <h3 className="fw-bold match-to-p">Initiate a connection to Chase.</h3>
      <p>
        Within your third-party app, provide a way for the the customer to
        connect with Chase. When the customer initiates this connection, launch
        another screen that provides details of what Chase information the
        customer is consenting to sharing.
      </p>
      <h3 className="fw-bold match-to-p">Request consent within your app.</h3>
      <p>
        On your app's consent screen, present a disclaimer for consent, and
        allow customers to back out or continue. If they continue, prompt them
        to sign in to Chase. Do this using the following endpoint within the
        User Consent API:
      </p>
      <ApiOperationCard resource="/aggregator-oauth/v1/authorize" />
      <p>
        Your app will launch the Chase's OAuth flow within a secure native
        browser within the app.
      </p>
      <h3 className="fw-bold match-to-p">
        Sign in to Chase (handled by Chase).
      </h3>
      <p>
        On the Chase welcome screen, the customer enters their username and
        password, and signs in to Chase.
      </p>
      <h3 className="fw-bold match-to-p">
        Provide consent to Chase (handled by Chase).
      </h3>
      <p>
        Chase informs the customer that they're consenting to share their
        account data with the third-party app. The customer chooses to continue.
      </p>
      <h3 className="fw-bold match-to-p">
        Confirm Chase account data to be shared (handled by Chase).
      </h3>
      <p>
        Chase lists the account details and data categories that will be shared
        with your app. The customer reviews the account details and chooses to
        continue.
      </p>
      <h3 className="fw-bold match-to-p">
        Select Chase accounts to connect to the third-party app (handled by
        Chase).
      </h3>
      <p>
        Chase displays a list of the customer accounts that can be connected
        with your app. The customer selects the accounts they wish to connect,
        and then chooses to continue.
      </p>
      <h3 className="fw-bold match-to-p">
        Review and confirm accounts to be shared (handled by Chase).
      </h3>
      <p>
        Chase displays a summary of the customer accounts to be connected to
        your app, along with the account details that will be shared. The
        customer chooses to continue, and they're redirected to your app.
      </p>
      <p>
        Now that consent has been confirmed with Chase, it's time for the
        customer to confirm which specific Chase accounts to connect to your
        app.
      </p>
      <div className="br-developer mb-5 mt-5" />
      {/*</Element>*/}

      <h2 className="mb-5 pt-1 developer-subheading" id="select-accounts">
        Allow customers to select Chase accounts
      </h2>
      <p>
        In the next part of this demo, you'll retrieve the customer's Chase
        account info and show them which accounts can be linked to your app.
      </p>
      <h3 className="fw-bold match-to-p">Confirm the connection to Chase.</h3>
      <p>
        Now that the customer has been redirected returned to your app, display
        a confirmation screen informing them that the app has connected to
        Chase. On this screen, include a list of the customer's Chase accounts.
        This list is generated via access token; to create the token, use the
        following endpoint within the User Consent API:
      </p>
      <ApiOperationCard resource="/aggregator-oauth/v1/token" />
      <h3 className="fw-bold match-to-p">
        Retrieve the eligible Chase Accounts.
      </h3>
      <p>
        Once you've created the token, you can then retrieve the Chase accounts
        that are available to be linked to your app.
      </p>
      <DeveloperCard borderLeft>
        <div className="mb-0">
          <strong>Note:</strong> The Chase accounts that were displayed when
          obtaining customer consent may not necessarily be available to use
          within your app. For example, a customer may have a Chase checking
          account and a Chase credit card, but only the checking account can be
          used for money movement within your app.
        </div>
      </DeveloperCard>
      <p>
        To retrieve the Chase accounts, use the following endpoint in the
        Account and Customer Information API:
      </p>
      <ApiOperationCard resource="/accounts" />
      <p>
        Display the available accounts in a selectable list and the unavailable
        accounts in a static list. From here, the the customer can select from
        the available accounts and then choose to continue. When they do this,
        your app must complete one final check before successfully linking the
        customer's Chase accounts.
      </p>
      <div className="br-developer mb-5 mt-5" />
      <h2 className="mb-5 pt-1 developer-subheading" id="confirm-accounts">
        Confirm linked accounts
      </h2>
      <p>
        In the final part of this demo, you'll show customers which Chase
        accounts have been successfully linked to your app. This includes
        checking whether the selected accounts move money through the Automated
        Clearing House (ACH) network.
      </p>
      <h3 className="fw-bold match-to-p">
        Display the Chase accounts that have been successfully linked.
      </h3>
      <p>
        Once the customer has selected their eligible Chase accounts, verify
        that the accounts use the ACH network. To do this, use the following
        endpoint within the Account and Customer Information API:
      </p>
      <ApiOperationCard resource="/accounts/{accountId}/payment-networks" />
      <p>
        This retrieves the payment networks supported by the Chase account. Once
        you've retrieved this final list of accounts, display the list of
        successfully linked accounts to the customer
      </p>
      <DeveloperCard borderLeft>
        <div className="mb-0">
          <strong>Note:</strong> Rather than using real account numbers, Chase
          uses Tokenized Account Numbers (TANs). These provide a secure method
          for sharing account numbers used for ACH transactions, reducing the
          risk of a customer's account number being compromised.
        </div>
      </DeveloperCard>
      {/*Future Doc Links will eventually go here*/}
      <div className="br-developer mb-5 mt-5" />
      <h2 className="mb-5 pt-1 developer-subheading" id="demo-complete">
        Demo complete
      </h2>
      <p>
        This demo shows how our APIs work together to enable your third-party
        app to connect to Chase customer accounts. This involves getting
        customer consent, retrieving the customer's Chase account info, and
        linking the accounts to your app.
      </p>
      <p>
        The APIs used in this demo include User Consent and Account and Customer
        Information.
      </p>
      {/*</div>*/}
    </div>
  );
}
