import React from 'react';
import AccountSelectionForm from '../forms/AccountSelectionForm';

export default function AccountSelectionScreen(props) {
  return (
    <div className="mx-3">
      <h4 className="h6">Which accounts do you want to connect?</h4>
      <p className="advisory font-small">
        These are all of your eligible accounts.
      </p>

      <AccountSelectionForm
        user={props.user}
        selectedAccounts={props.selectedAccounts}
        setSelectedAccounts={props.setSelectedAccounts} />
    </div>
  );
}
