import axios, { AxiosInstance } from "axios";

const BASE_URL = "/api";

const apiClient: AxiosInstance = axios.create({
  baseURL: BASE_URL,
});

export default apiClient;

type Feature = {
  name: string;
  enabled: boolean;
}

export const fetchAllFeatures = async () => {
  const res = await apiClient.get("/v1/features/");
  const resData = res.data;
}

export const fetchFeature = async (featureName: string) => {
  const res = await apiClient.get(`/v1/features/${featureName}`);
  const resData = res.data;
}
