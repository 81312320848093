import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useLayoutEffect,
} from "react";
import _ from "lodash";
import SyntaxHighlighter from "react-syntax-highlighter";
import ChaseTheme from "../../../accounts-and-data-sharing/code-view/ChaseTheme";
import { StoreContext } from "../../hooks";
import {
  ValueContainer,
  CustomOption,
  dropdownStyles,
} from "../presentational/dropdown";
import Select from "react-select";
import { CopyButton } from "../presentational";
import ReadyToBuild from "../presentational/ReadyToBuild";

const replaceEndpoint = (codeString: string) => {
  /* replaces placeholder text in code snippets with host URL */
  const regex = /(@@host@@)/g;
  const newEndpoint = codeString.replace(regex, `${window.location.origin}`);
  return newEndpoint;
};
interface CodePaneProps {
  snippets: {
    flow: number;
    apiName: string;
    language: string;
    content: string;
  }[];
}

interface LanguageDictShape {
  [language: string]: {
    presentationalName: string;
    tagName: string;
  };
}
const languageDict: LanguageDictShape = {
  javascript: {
    presentationalName: "JavaScript",
    tagName: "js",
  },
  yaml: {
    presentationalName: "YAML",
    tagName: "yaml",
  },
  python: {
    presentationalName: "Python",
    tagName: "py",
  },
  ruby: {
    presentationalName: "Ruby",
    tagName: "rb",
  },
  java: {
    presentationalName: "Java",
    tagName: "java",
  },
  go: {
    presentationalName: "Go",
    tagName: "go",
  },
  php: {
    presentationalName: "PHP",
    tagName: "php",
  },
  dotnet: {
    presentationalName: ".NET",
    tagName: "dotnet",
  },
};

const CodePane: React.FC<CodePaneProps> = (props) => {
  const { snippets } = props;
  const snippetsByFlow = _.values(_.groupBy(snippets, "flow"));
  const languagesAvailable = Object.keys(_.groupBy(snippets, "language"));
  const { currentFlow, endpointSnippet } = useContext(StoreContext);
  const [languageIdx, setLanguageIdx] = useState(0);
  const [menuHasOpened, setMenuHasOpened] = useState(false);
  const [codeIdx, setCodeIdx] = useState(0);
  const codePaneRef = useRef<any>();

  const [selected, setSelected] = useState(
    snippetsByFlow[codeIdx][languageIdx]
  );
  const selectOptions = snippetsByFlow.map((api, i) => ({
    value: api[languageIdx],
    label: api[languageIdx].apiName,
    AATagDescriptor: languageDict[languagesAvailable[languageIdx]].tagName,
  }));

  const AATAG = "chaseanalytics-track-link";

  useEffect(() => {
    /* useEffect that tracks changes in codeIdx and languageIdx and updates state accordingly*/
    setSelected(snippetsByFlow[codeIdx][languageIdx]);
  }, [currentFlow, languageIdx, snippetsByFlow]);

  useEffect(() => {
    /* Checks updates in context and changes local state*/
    setCodeIdx(currentFlow);
  }, [currentFlow]);

  const getDataPtName = (dpn: string, name: string, flow: number) => {
    const dataPointName = dpn.concat(flow + "_").concat(
      name
        .split(" ")
        .map((w) => w[0].toLowerCase())
        .join("")
    );
    return dataPointName;
  };

  useLayoutEffect(() => {
    if (codePaneRef.current) {
      const codePane = codePaneRef.current.children[1]; // have to drill into ref because of custom component
      const spans: HTMLSpanElement[] = Array.from(
        codePane.getElementsByTagName("span")
      );
      if (languagesAvailable[languageIdx] === "yaml" && endpointSnippet) {
        // if CodePane is displaying YAML
        spans.forEach((span) => {
          if (span.innerHTML.includes(endpointSnippet)) {
            span.classList.add("highlight");
            span.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "nearest",
            });
          }
        });
      }
    }

    return () => {
      // cleanup function removes all classes set from useEffect
      if (codePaneRef.current) {
        const codePane = codePaneRef.current.children[1];
        const selectedSpans: HTMLSpanElement[] = Array.from(
          codePane.getElementsByClassName("highlight")
        );
        selectedSpans.forEach((span) => span.classList.remove("highlight"));
      }
    };
  }, [endpointSnippet, selected, codePaneRef.current]);

  return (
    <>
      <div className="code-pane">
        <label style={{ width: "100%" }} htmlFor="dropdown menu">
          <div data-testid="dropdown-menu">
            <Select
              onChange={(selectedOption) =>
                setCodeIdx(selectedOption!.value.flow)
              }
              defaultValue={selectOptions[0]}
              value={selectOptions[codeIdx]}
              isSearchable={false}
              className="select-container"
              classNamePrefix="react-select"
              options={selectOptions}
              components={{
                ValueContainer,
                Option: CustomOption,
                IndicatorSeparator: () => null,
              }}
              styles={dropdownStyles}
              openMenuOnFocus={true}
              onMenuOpen={() => setMenuHasOpened(true)}
              onMenuClose={() => setMenuHasOpened(false)}
            />
          </div>
        </label>
        <div id="snippet-language-buttons-container">
          {languagesAvailable.map((language: string, i) => {
            return (
              <button
                key={i}
                // JavaScript button
                className={`${AATAG} ${
                  selected.language === language ? "active" : ""
                }`}
                data-pt-name={
                  getDataPtName("nav_code_", selected.apiName, selected.flow) +
                  "_" +
                  languageDict[language].tagName
                }
                aria-label={
                  selected.language === language
                    ? `${languageDict[language].presentationalName} button selected`
                    : `${languageDict[language].presentationalName} button unselected`
                }
                onClick={() => setLanguageIdx(i)}
              >
                {languageDict[language].presentationalName}
              </button>
            );
          })}
        </div>

        <div
          className="text-light"
          id="copy-button-and-rendered-code-container"
          ref={codePaneRef}
        >
          <CopyButton codeSnippetText={replaceEndpoint(selected.content)} />
          <SyntaxHighlighter
            language={selected.language}
            style={ChaseTheme}
            className="code-snippet"
            id="code-pane-main"
            lineProps={{
              style: {
                flexWrap: "wrap",
                wordBreak: "break-all",
                display: "block",
              },
            }}
            showLineNumbers
            wrapLines={true}
          >
            {replaceEndpoint(selected.content)}
          </SyntaxHighlighter>
        </div>
        <ReadyToBuild />
      </div>
    </>
  );
};

export default CodePane;
